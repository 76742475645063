import React, { useEffect, useState, useCallback } from "react";
import { Button, Form, Input, message, Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { BaseURL } from "../../api/BaseURL";
import { useSelector } from "react-redux";

const { Option } = Select;

const EditCourseDashboard = () => {
    const { courseId } = useParams();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);
    const token = localStorage.getItem("token_Value");
    const { user } = useSelector((state) => state.auth);

    const fetchCourse = useCallback(async () => {
        try {
            const response = await axios.get(`${BaseURL}courses/${courseId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            });

            if (response.data.course) {
                const {
                    name,
                    // code,
                    description,
                    category,
                    instructor,
                    level,
                    sections,
                    duration,
                    image,
                    price,
                } = response.data.course;

                form.setFieldsValue({
                    name,
                    // code,
                    description,
                    category,
                    instructor,
                    level,
                    sections,
                    duration,
                    price,
                });
                setFile(image); // Optionally set existing image URL or object
            }
        } catch (error) {
            console.error("Failed to load course details", error);
            message.error("Could not load course details.");
        }
    }, [courseId, form, token]);

    useEffect(() => {
        fetchCourse();
    }, [fetchCourse]);

    const onFinish = async (values) => {
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append("name", values.name);
            // formData.append("code", values.code);
            formData.append("description", values.description);
            formData.append("category", values.category);
            formData.append("instructor", user.id);
            formData.append("level", values.level);
            formData.append("sections", values.sections);
            formData.append("duration", values.duration);
            if (file) {
                formData.append("image", file);
            }
            formData.append("price", values.price);

            await axios.put(`${BaseURL}courses/${courseId}`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
            });

            form.resetFields(); // Reset form fields after success
            navigate("/courses-dashboard");
            message.success("Course updated successfully");
        } catch (error) {
            console.error("Failed to update course", error);
            message.error("Failed to update course. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ padding: "20px", maxWidth: "600px", margin: "0 auto" }}>
            <Button
                        type="text"
                        onClick={() => navigate(-1)} // Or use navigate(-1) for going back to previous page
                        style={{ marginRight: "10px" }}
                    >
                        {"< Back"}
                    </Button>
            <h2 style={{ textAlign: "center", marginBottom: "20px" }}>Edit Course</h2>
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item label="Course Name" name="name" rules={[{ required: true, message: "Please input course name!" }]}>
                    <Input placeholder="Course Name" />
                </Form.Item>

                {/* <Form.Item label="Course Code" name="code" rules={[{ required: true, message: "Please input course code!" }]}>
                    <Input placeholder="Course Code" />
                </Form.Item> */}

                <Form.Item label="Description" name="description" rules={[{ required: true, message: "Please input course description!" }]}>
                    <Input.TextArea rows={4} placeholder="Description" />
                </Form.Item>

                <Form.Item label="Category" name="category" rules={[{ required: true, message: "Please select a category!" }]}>
                    <Select placeholder="Select a category" allowClear>
                        <Option value="Marketing">Marketing</Option>
                        <Option value="Programming Languages">Programming Languages</Option>
                        <Option value="Web Development">Web Development</Option>
                        <Option value="Data Science">Data Science</Option>
                        <Option value="Mobile Development">Mobile Development</Option>
                        <Option value="Artificial Intelligence">Artificial Intelligence</Option>
                        <Option value="Software Design">Software Design</Option>
                        <Option value="Design">Design</Option>
                        <Option value="Other">Other</Option>
                    </Select>
                </Form.Item>

                <Form.Item label="Level" name="level" rules={[{ required: true, message: "Please select a level!" }]}>
                    <Select placeholder="Select a level" allowClear>
                        <Option value="Beginner">Beginner</Option>
                        <Option value="Intermediate">Intermediate</Option>
                        <Option value="Advanced">Advanced</Option>
                    </Select>
                </Form.Item>

                <Form.Item label="Sections" name="sections">
                    <Input placeholder="Number of Sections" type="number" />
                </Form.Item>

                <Form.Item label="Duration" name="duration">
                    <Input placeholder="Duration (e.g., 10 hours)" />
                </Form.Item>

                <Form.Item label="Image" rules={[{ required: true, message: "Please upload an image!" }]}>
                    <Input
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                            setFile(e.target.files[0]);
                            console.log(file);
                        }}
                    />
                </Form.Item>

                <Form.Item label="Price" name="price" rules={[{ required: true, message: "Please input price!" }]}>
                    <Input placeholder="Price" type="number" />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading} style={{ width: "100%",backgroundColor: '#008772' }} disabled={loading}>
                        Update Course
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default EditCourseDashboard;
