import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BaseURL } from '../../api/BaseURL';
import { useMediaQuery } from 'react-responsive';
import Style from "../FifthSection/FifthSection.module.css";
import man2 from "../../Assets/man2.jpg";
import { useDarkMode } from '../Contexts/DarkModeContext';
import { Link } from 'react-router-dom';

const AllAbrovedCourses = () => {
    const { darkMode } = useDarkMode();
    const isScreenSmall = useMediaQuery({ maxWidth: 576 });
    const [courses, setCourses] = useState(null);
    const token = localStorage.getItem("token_Value");
    
    const getCourses = () => {
        axios.get(BaseURL + `courses/my_courses`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            const transformedCourses = response.data.courses.map((course) => ({
                ...course,
            }));
            setCourses(transformedCourses);
            console.log(transformedCourses);
        }).catch((error) => {
            console.log(error);
            // setError(error.message)
        });
    };

    useEffect(() => {
        getCourses();
    }, []);

    return (
        <div className={darkMode ? "bg-dark text-white" : "bg-white text-black"}>
            {courses && courses.length > 0 ? (
                <div className="container">
                    <div className="row py-5">
                        {courses.map((course) => (
                            <div key={course.id} className="col-md-4">
                                <Link to={`/courses/${course.id}`}>
                                    <div className="bg-white shadow rounded-4 p-3 my-4">
                                        <div>
                                            <p className="small-font gray-text mt-1 fw-bold">{course.category}</p>
                                            <img src={course.image} className="w-100 rounded-3" alt="" />
                                        </div>
                                        <p className="fw-bold my-2">{course.name}</p>
                                        <div className="d-flex w-50 align-items-center">
                                            <p className="fw-bold my-3 font-sm">
                                                {course.user.firstName + ' ' + course.user.lastName}
                                            </p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div className="text-center py-5">
                    <p className="fw-bold py-5 my-5">
                        You are not enrolled in any courses yet.
                    </p>
                    <Link to={`/categories`} className="fw-bold my-5">
                        Go to Categories Page
                    </Link>
                </div>
            )}
        </div>
    );
};

export default AllAbrovedCourses;
