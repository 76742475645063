import React, { useState } from "react";
import Style from "./DashboardNavbar.module.css"; // Ensure your CSS module has the necessary styles
import { RiHomeLine, RiMiniProgramLine, RiProgress1Line } from "react-icons/ri";
import logo from "../../Assets/footerlogo.png";
import {
  IoNotificationsOutline,
  IoSettingsOutline,
  IoSearchOutline,
} from "react-icons/io5";
import { RiUserSettingsLine } from "react-icons/ri";
import { MdEventAvailable, MdInsertChartOutlined } from "react-icons/md";
import { CiViewBoard } from "react-icons/ci";
import { NavLink } from "react-router-dom"; // Use NavLink instead of Link
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../features/authSlice";
import { Button } from "antd";
import { LogoutOutlined, MenuOutlined } from "@ant-design/icons";
import { FaNewspaper } from "react-icons/fa6";
import { PiTreeEvergreenThin } from "react-icons/pi";

const sideNavData = [
  {
    icon: <MdInsertChartOutlined />,
    title: "Dashboard",
    path: "/admin-dashboard",
  },
  { icon: <CiViewBoard />, title: "Courses", path: "/courses-dashboard" },
  { icon: <FaNewspaper />, title: "News", path: "/news-dashboard" },
  { icon: <MdEventAvailable />, title: "Events", path: "/events-dashboard" },
  // Add more navigation items here if needed
];

const renderSideNavData = () => {
  return sideNavData.map((data, index) => (
    <NavLink
      key={index}
      to={data.path}
      className={({ isActive }) =>
        `p-2 rounded-3 fs-5 d-flex align-items-center my-2 ${
          isActive ? "bg-color text-white" : "textGray"
        }`
      }
    >
      <div className="d-flex align-items-center">
        {data.icon}
        <span className="mx-2">{data.title}</span>
      </div>
    </NavLink>
  ));
};

export default function Navbar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  return (
    <>
      {/* Sidebar for larger screens */}
      <div
        className={`position-fixed border-end top-0 bottom-0 start-0 bg-white d-flex flex-column justify-content-between d-none d-md-flex`}
        style={{ width: "190px", zIndex: 1000 }}
      >
        {/* Top Section: Logo and Navigation Links */}
        <div className="d-flex flex-column align-items-center p-3">
          <img src={logo} className="rounded-pill w-75 mb-4" alt="Logo" />
          <div className="w-100">{renderSideNavData()}</div>
        </div>

        {/* Bottom Section: Logout Button */}
        <div className="p-3">
          <Button
            icon={<LogoutOutlined />}
            type=""
            onClick={handleLogout}
            className="w-100"
          >
            Logout
          </Button>
        </div>
      </div>

      {/* Button for toggling sidebar in small screens */}
      <div className="d-md-none p-2 bg-color text-white d-flex justify-content-end">
        <button
          className="btn text-white"
          onClick={toggleSidebar}
          style={{ fontSize: "1.5rem" }}
        >
          <MenuOutlined />
        </button>
      </div>

      {/* Sidebar offcanvas for small screens */}
      {isSidebarVisible && (
        <div
          className="position-fixed top-0 end-0 bottom-0 bg-white"
          style={{ width: "190px", zIndex: 1050 }}
        >
          {/* Top Section: Logo and Navigation Links */}
          <div className="d-flex flex-column align-items-center p-3">
            <img src={logo} className="rounded-pill w-75 mb-4" alt="Logo" />
            <div className="w-100">{renderSideNavData()}</div>
          </div>

          {/* Bottom Section: Logout Button */}
          <div className="p-3">
            <Button
              icon={<LogoutOutlined />}
              type=""
              onClick={() => {
                handleLogout();
                setSidebarVisible(false);
              }}
              className="w-100"
            >
              Logout
            </Button>
          </div>

          {/* Close Sidebar */}
          <div
            className="position-absolute top-0 start-0 p-3"
            style={{ cursor: "pointer" }}
            onClick={toggleSidebar}
          >
            &times;
          </div>
        </div>
      )}
    </>
  );
}
