import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Result, Button } from "antd";

export default function Success() {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(5);

  // Countdown timer and redirect logic
  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev === 1) {
          navigate("/"); // Redirect to the home page after 5 seconds
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer); // Cleanup timer on component unmount
  }, [navigate]);

  return (
    <div className="container text-center" style={{ marginTop: "50px", marginBottom: "150px" }}>
      <Result
        status="success"
        title="Payment Successful"
        subTitle="Thank you for subscribing! Redirecting to the home page..."
        extra={[
          <Button
            type=""
            className="bg-color text-white"
            key="home"
            onClick={() => navigate("/")}
          >
            Go to Home Now
          </Button>,
        ]}
      />
      <p>Redirecting in {countdown} seconds...</p>
    </div>
  );
}
