/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Style from "./Navbar.module.css";
import logo from "../../../Assets/cleanlogo.png";
import logoWhite from "../../../Assets/logoWhite.png";
import logoBlack from "../../../Assets/logoBlack.png";
import egypt from "../../../Assets/egypt.svg";
import ksa from "../../../Assets/ksa.svg";
import usa from "../../../Assets/usa.svg";
import img12 from "../../../Assets/img12.jpg";
import { Link, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import DashboardNavbar from "../../DashboardNavbar/DashboardNavbar";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { logout } from "../../../features/authSlice";
import { useDarkMode } from '../../Contexts/DarkModeContext';
import { useTranslation } from "react-i18next"; // Import i18next hook
export default function Navbar() {
  const { darkMode, toggleDarkMode } = useDarkMode();
  const location = useLocation();

  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState({ code: "en", flag: usa });
  const isScreenSmall = useMediaQuery({ minWidth: 0, maxWidth: 768 });
  const navbarItems = [{ title: "Home" }];

  const { user } = useSelector((state) => state.auth);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false); // Control navbar toggle
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const logoutHandler = () => {
    navigate("/");
    Cookies.remove("user");
    dispatch(logout());
    setIsNavbarOpen(false); // Close navbar on action
  };

  const handleLanguageChange = (lang, flag) => {
    i18n.changeLanguage(lang);
    document.documentElement.dir = lang === "ar" ? "rtl" : "ltr";
    setSelectedLanguage({ code: lang, flag });
    setLanguageDropdownOpen(false);
    setIsNavbarOpen(false); // Close navbar on action
  };
  const handleToggleClick = () => setIsNavbarOpen(!isNavbarOpen); // Toggle navbar state

  const closeNavbar = () => setIsNavbarOpen(false); // Function to close navbar

  return (
    <>
      {location.pathname === "/admin-dashboard" ||
        location.pathname === "/page2" ||
        location.pathname === "/invoice-dashboard" ||
        location.pathname === "/courses-dashboard" ||
        location.pathname === "/courseDetails-dashboard" ||
        location.pathname === "/createNewCourse-dashboard" ||
        location.pathname === "/addCourse-dashboard" ? (
        <>
          {" "}
          <DashboardNavbar />{" "}
        </>
      ) : (
        <>
          <div className="">
            {["/courses", "/quiz", "/profile"].includes(location.pathname) ? (
              <>
                <nav
                  className={`navbar navbar-expand-lg  p-0 ${darkMode ? "bg-dark text-light" : "bg-white text-dark"}`}
                >
                  <div className="container-fluid">
                    <Link className="navbar-brand" to={"/home"}>
                      <img
                        src={`${darkMode ? logoWhite :logoBlack}`}
                        className=""
                        alt="logo"
                        style={{ width: "50px", height: "50px" }}
                      />
                    </Link>
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent"
                      aria-label="Toggle navigation"
                      onClick={handleToggleClick}
                      aria-expanded={isNavbarOpen}
                    >
                      <span className="navbar-toggler-icon"></span>
                    </button>
                    <div
                      className={`collapse navbar-collapse ${isNavbarOpen ? "show" : ""} ${["/courses", "/quiz", "/profile"].includes(location.pathname) ? "justify-content-around" : "justify-content-between"}`}
                      id="navbarSupportedContent"
                    >
                      <ul className="navbar-nav">
                        <li className="nav-item">
                          <Link
                            to={"/home"}
                            onClick={closeNavbar} className={`nav-link ${darkMode ? " text-light" : " text-dark"} `}
                            href="#"
                          >
                            {i18n.t('navbar.home')}
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            onClick={closeNavbar} className={`nav-link ${darkMode ? " text-light" : " text-dark"}`}
                            to={"/categories"}
                          >
                            {i18n.t('navbar.categories')}
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link onClick={closeNavbar} className={`nav-link ${darkMode ? " text-light" : " text-dark"}`} to={"/all-courses"}>
                          {i18n.t('navbar.courses')}
                          </Link>
                        </li>
                        {/* <li className="nav-item">
                          <Link onClick={closeNavbar} className={`nav-link ${darkMode ? " text-light" : " text-dark"}`} to={"/quiz"}>
                            Quiz
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link onClick={closeNavbar} className={`nav-link ${darkMode ? " text-light" : " text-dark"}`} to={"/profile"}>
                            Profile
                          </Link>
                        </li> */}
                        <li className="nav-item">
                          <Link onClick={closeNavbar} className={`nav-link ${darkMode ? " text-light" : " text-dark"}`} to={"/pricing"}>
                          {i18n.t('navbar.pricing')}
                          </Link>
                        </li>
                      </ul>
                      <form
                        className="ps-4"
                        style={{
                          width:
                            isScreenSmall &&
                              ["/courses", "/quiz", "/profile"].includes(
                                location.pathname,
                              )
                              ? "100%"
                              : "40%",
                        }}
                      >
                        <div
                          className="position-relative"
                          style={{ width: "80%" }}
                        >
                          <input
                            className="gray-border mx-2 py-1 rounded-5 ps-4 w-100 text-dark"
                            aria-label="Search"
                            placeholder={i18n.t('navbar.searchPlaceholder')}
                          />
                          <i
                            className="fa-solid fa-magnifying-glass position-absolute ms-3 gray-text small-font"
                            style={{
                              top: "50%",
                              transform: "translateY(-50%)",
                            }}
                          ></i>
                        </div>
                      </form>
                      <button onClick={toggleDarkMode}>{darkMode ? <i className="fa-solid fa-sun text-light text-2xl"></i> : <i className="fa-solid fa-moon text-dark text-light text-2xl"></i>}</button>
                      <div className="dropdown">
                    <button className="btn p-0 me-3" onClick={() => setLanguageDropdownOpen(!languageDropdownOpen)}>
                      <i className="fa-solid fa-globe text-base text-neutral-900 dark:text-white" title={i18n.language === "en" ? "Switch Language" : "تغيير اللغة"}></i>
                    </button>
                    {languageDropdownOpen && (
                      <ul className="dropdown-menu show">
                        <li>
                          <button className="dropdown-item" onClick={() => {
                            handleLanguageChange("ar")
                            closeNavbar()
                          }}>
                            <img src={usa} alt="English" style={{ width: "20px", marginRight: "8px" }} /> English
                          </button>
                        </li>
                        <li>
                          <button className="dropdown-item" onClick={() => {
                            handleLanguageChange("ar")
                            closeNavbar()
                          }}>
                            <img src={egypt} alt="Arabic (Egypt)" style={{ width: "20px", marginRight: "8px" }} /> العربية (Egypt)
                          </button>
                        </li>
                        <li>
                          <button className="dropdown-item" onClick={() => {
                            handleLanguageChange("ar")
                            closeNavbar()
                          }}>
                            <img src={ksa} alt="Arabic (KSA)" style={{ width: "20px", marginRight: "8px" }} /> العربية (KSA)
                          </button>
                        </li>
                      </ul>
                    )}
                  </div>                    </div>
                  </div>
                </nav>
              </>
            ) : (
              <>
                <nav
                  className={`navbar navbar-expand-lg p-0 ${darkMode ? "bg-dark text-light" : "bg-white text-dark"
                    } `}
                >
                  <div className="container">
                    <Link className="navbar-brand" to={"/home"}>
                      <img
                        src={`${darkMode ? logoWhite :logoBlack}`}
                        className=""
                        alt="logo"
                        style={{ width: "50px", height: "50px" }}
                      />
                    </Link>
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent"
                      onClick={handleToggleClick}
                      aria-expanded={isNavbarOpen}
                      aria-label="Toggle navigation"
                    >
                      <span className="navbar-toggler-icon"></span>
                    </button>
                    <div
                      className={`collapse navbar-collapse justify-content-between ${isNavbarOpen ? "show" : ""}`}
                      id="navbarSupportedContent"
                    >
                      <ul className="navbar-nav">
                        <li className="nav-item">
                          {user?(<Link
                            to={"/library"}
                            onClick={closeNavbar}
                            className={`nav-link ${darkMode ? " text-light" : " text-dark"} `}
                            href="#"
                          >
                            {i18n.t('navbar.home')}
                          </Link>):(<Link
                            to={"/home"}
                            onClick={closeNavbar}
                            className={`nav-link ${darkMode ? " text-light" : " text-dark"} `}
                            href="#"
                          >
                            {i18n.t('navbar.home')}
                          </Link>)}
                        </li>
                        <li className="nav-item">
                          <Link
                          onClick={closeNavbar}
                            className={`nav-link ${darkMode ? " text-light" : " text-dark"}`}
                            to={"/categories"}
                          >
                            {i18n.t('navbar.categories')}
                          </Link>
                        </li>
                        <li className="nav-item">
                          {user?(<Link className={`nav-link ${darkMode ? " text-light" : " text-dark"}`} onClick={closeNavbar} to={"/all-courses"}>
                          {i18n.t('navbar.courses')}
                          </Link>):(<></>)}
                        </li>
                        {/* <li className="nav-item">
                          <Link className={`nav-link ${darkMode ? " text-light" : " text-dark"}`} to={"/quiz"}>
                            Quiz
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link className={`nav-link ${darkMode ? " text-light" : " text-dark"}`} to={"/profile"}>
                            Profile
                          </Link>
                        </li> */}
                        <li className="nav-item">
                          <Link className={`nav-link ${darkMode ? " text-light" : " text-dark"}`} onClick={closeNavbar} to={"/pricing"}>
                          {i18n.t('navbar.pricing')}
                          </Link>
                        </li>
                      </ul>
                      <form
                        className="ps-4"
                        style={{ width: isScreenSmall ? "100%" : "40%" }}
                      >
                        <div
                          className="position-relative"
                          style={{ width: "80%" }}
                        >
                          <input
                            className="gray-border mx-2 py-1 rounded-5 px-4 w-100 text-dark"
                            aria-label="Search"
                            placeholder={i18n.t('navbar.searchPlaceholder')}
                          />
                          <i
                            className="fa-solid fa-magnifying-glass position-absolute mx-3 gray-text small-font"
                            style={{
                              top: "50%",
                              transform: "translateY(-50%)",
                            }}
                          ></i>
                        </div>
                      </form>

                      <div
                        className={`${isScreenSmall ? "d-flex justify-content-center my-3" : ""}`}
                      >
                        {user?.id ? (
                          <div
                            style={{ height: "100px" }}
                            className="d-flex align-items-center px-5"
                          >
                            <img
                              src={user.image}
                              style={{ height: "50px" }}
                              className="rounded-pill"
                              alt=""
                              onClick={() => navigate("/profile")}
                            />
                            <p className="font-sm fw-bold mx-2">
                              {user?.firstName || "User"}
                            </p>
                            <div className="dropdown">
                              <i
                                className="fa-solid fa-angle-down" //dropdown-toggle"
                                data-bs-toggle="dropdown"
                              ></i>
                              <ul className="dropdown-menu">
                                <li>
                                  <button
                                    className="dropdown-item1"
                                    onClick={() => {
                                      navigate("/user")
                                      closeNavbar()
                                    }}
                                  >
                                    {i18n.t('navbar.profile')}
                                  </button>
                                </li>
                                <li>
                                  <button
                                    className="dropdown-item2"
                                    onClick={logoutHandler}
                                  >
                                    {i18n.t('navbar.logout')}
                                  </button>
                                </li>
                              </ul>
                            </div>

                          </div>
                        ) : (
                          <>
                            <Link
                              to={"/login"}
                              className={`btn mx-2 rounded-5 border-white border-2 text-color me-4 px-4 py-1 btn-sm ${location.pathname !== "/signup" ? "bg-color text-white" : location.pathname === "/home" ? "text-white border-white" : ""}`}
                              type="button"
                              onClick={closeNavbar}
                            >
                              {i18n.t('navbar.login')}
                            </Link>
                            <Link
                              to={"/signup"}
                              className={`btn rounded-5 text-color px-4 py-1 btn-sm main-border ${location.pathname === "/signup" ? "bg-color text-white" : location.pathname === "/home" ? "bg-white" : ""}`}
                              type="button"
                              onClick={closeNavbar}
                            >
                              {i18n.t('navbar.signup')}
                            </Link>
                          </>
                        )}
                      </div>
                      <button onClick={() => {
                            toggleDarkMode()
                            closeNavbar()
                          }}>{darkMode ? <i className="fa-solid fa-sun text-light text-2xl"></i> : <i className="fa-solid fa-moon text-dark text-light text-2xl"></i>}</button>
                      <div className="dropdown">
                    <button className="btn p-0 mx-4" onClick={() => setLanguageDropdownOpen(!languageDropdownOpen)}>
                      <img src={selectedLanguage.flag} alt="Selected Language" style={{ width: "20px" }} />
                    </button>
                    {languageDropdownOpen && (
                      <ul className="dropdown-menu show">
                        <li>
                          <button className="dropdown-item" onClick={() => {
                            handleLanguageChange("en",usa)
                            closeNavbar()
                          }}>
                            <img src={usa} alt="English" style={{ width: "20px", marginRight: "1px" }} /> English
                          </button>
                        </li>
                        <li>
                          <button className="dropdown-item" onClick={() => {
                            handleLanguageChange("ar",egypt)
                            closeNavbar()
                          }}>
                            <img src={egypt} alt="Arabic (Egypt)" style={{ width: "20px", marginRight: "1px" }} /> العربية (Egypt)
                          </button>
                        </li>
                        <li>
                          <button className="dropdown-item" onClick={() => {
                            handleLanguageChange("ar",ksa)
                            closeNavbar()
                          }}>
                            <img src={ksa} alt="Arabic (KSA)" style={{ width: "20px", marginRight: "1px" }} /> العربية (KSA)
                          </button>
                        </li>
                      </ul>
                    )}
                  </div>  
                  </div>
                  </div>
                </nav>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}
