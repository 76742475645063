import React, { useEffect } from 'react'
import { Link, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Overview from './Overview/Overview';
import Assignment from './Assignment/Assignment';
import Reports from './Reports/Reports';
import FileStorage from './FileStorage/FileStorage';
import Inbox from './Inbox/Inbox';
import Settings from './Settings/Settings';
import UserSideBar from './UserSidebar/UserSideBar';
import { useDarkMode } from '../Contexts/DarkModeContext';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { motion, AnimatePresence } from "framer-motion";
import LessonAssign from './LessonAssign/LessonAssign';

const UserProfile = () => {
    const { darkMode } = useDarkMode();
    const isScreenSmall = useMediaQuery({ minWidth: 0, maxWidth: 768 });
    const { user } = useSelector((state) => state.auth);
    return (
        <div className={`${darkMode ? 'bg-dark' : 'bg-white'} ${darkMode ? 'text-white' : 'text-black'}`}>
            <div className='container pt-4'>
                {/* <h1 className={'text-center mb-3'}>Dashboard</h1> */}
                <div className="row">
                    <div className="col-md-2 mb-3">
                        <div className="position-sticky" style={{ top: '10px' }}>
                            <UserSideBar />
                        </div>
                    </div>

                    <div className="col-md-10">
                    <div className={`d-flex justify-content-between align-items-center ${isScreenSmall ? "flex-column" : ""}`}>
                    <div className="ps-4">
                            <div
                                className={`d-flex align-items-center ${isScreenSmall ? "justify-content-center mt-3" : ""}`}
                            >
                                <h4 className="fw-bold me-2">Hello {user?.firstName}</h4>
                                <p style={{ display: "inline" }}>&#128075;</p>
                            </div>
                            <p className="fw-bold gray-text font-sm">
                                Let's learn something new today
                            </p>
                        </div>
                        {/* <div className="d-flex align-items-center justify-content-between">
                            <input
                                type="text"
                                style={{ width: "300px" }}
                                className="ps-2 border rounded-2 text-black"
                                placeholder="Search from courses..."
                            />

                            <div className="d-flex border border-1 p-1 rounded-2">
                                <i class="fa-regular fa-bell position-relative fs-5">
                                    <div
                                        className="p-1 bg-danger rounded-pill position-absolute top-0 end-0"
                                        style={{ width: "3px" }}
                                    ></div>
                                </i>
                            </div>
                        </div> */}
                    </div>
                        
                    <AnimatePresence mode="wait">
                    <Routes>
                        <Route path="overview" element={<Overview user={user} />} />
                        <Route path="assignment" element={<Assignment />} />
                        <Route path=":courseId/:quizId" element={<LessonAssign/>} />
                        <Route path="reports" element={<Reports />} />
                        <Route path="file-storage" element={<FileStorage />} />
                        <Route path="inbox" element={<Inbox />} />
                        <Route path="settings" element={<Settings />} />
                        <Route path="*" element={<></>} />
                    </Routes>

                        </AnimatePresence>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default UserProfile