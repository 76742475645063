import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button, Typography } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { BaseURL } from "../../api/BaseURL";
import { useMediaQuery } from "react-responsive";

const { Title, Paragraph } = Typography;

const CourseDetails = () => {
  const navigate = useNavigate();
  const { courseId } = useParams();
  const token = localStorage.getItem("token_Value");

  const [course, setCourse] = useState(null);
  const [lessons, setLessons] = useState(null);
  const [quizzes, setQuizzes] = useState(null);

  const isSmallScreen = useMediaQuery({ maxWidth: 768 });

  // Fetch course details
  const getCourse = () => {
    axios
      .get(`${BaseURL}courses/${courseId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setCourse(response.data.course);
        console.log(response.data.course);
      })
      .catch((error) => {
        console.error("Error fetching course:", error);
      });
  };

  // Fetch lessons
  const getLessons = () => {
    axios
      .get(`${BaseURL}courses/${courseId}/lessons`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setLessons(response.data.lessons);
      })
      .catch((error) => {
        console.error("Error fetching lessons:", error);
      });
  };

  // Fetch quizzes
  const getQuizzes = () => {
    axios
      .get(`${BaseURL}courses/${courseId}/quizzes`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setQuizzes(response.data.quizzes);
      })
      .catch((error) => {
        console.error("Error fetching quizzes:", error);
      });
  };

  useEffect(() => {
    if (courseId) {
      getCourse();
      getLessons();
      getQuizzes();
    }
  }, [courseId]);

  return (
    <div className="row" style={{ backgroundColor: "#F6FAFB" }}>
      {/* Main Content */}
      <div className="col-12 px-3" style={{ padding: "20px" }}>
        {/* Dashboard Header */}
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ paddingBottom: "10px" }}
        >
          <div className="d-flex align-items-center">
            <Button
              type="text"
              onClick={() => navigate(-1)}
              style={{ marginRight: "10px" }}
            >
              {"< Back"}
            </Button>
            <div className="w-100 d-flex justify-content-center align-items-end">
              <h1
                className="m-0"
                style={{
                  fontSize: isSmallScreen ? "18px" : "28px",
                  fontWeight: "600",
                }}
              >
                Dashboard
              </h1>
              <p
                className="ml-3 text-muted"
                style={{
                  marginLeft: "10px",
                  fontSize: isSmallScreen ? "12px" : "16px",
                }}
              >
                / Courses / Course Detail
              </p>
            </div>
          </div>
        </div>

        {/* Course Details */}
        <Card
          style={{
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0 1px 4px rgba(0,0,0,0.1)",
            marginTop: "20px",
          }}
        >
          <Row gutter={16}>
  {/* Image Column */}
  <Col
    xs={24}
    md={12}
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <img
      src={course?.image}
      alt="Course"
      style={{
        width: "60%", // Reduced width to make the image smaller
        height: "auto", // Maintain aspect ratio
        objectFit: "cover",
        borderRadius: "8px",
        boxShadow: "0 1px 4px rgba(0,0,0,0.1)",
      }}
    />
  </Col>

  {/* Details Column */}
  <Col
    xs={24}
    md={12}
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    }}
  >
    <Title level={3} style={{ fontSize: isSmallScreen ? "18px" : "24px" }}>
      {course?.name}
    </Title>
    <Paragraph>Category: {course?.category}</Paragraph>
    <Paragraph>Description: {course?.description}</Paragraph>
    <Paragraph>Level: {course?.level}</Paragraph>
    <Paragraph>Duration: {course?.duration} Hours</Paragraph>
    <Paragraph>
      Rating:{" "}
      {Array.from({ length: course?.rate }).map((_, starIndex) => (
        <i
          key={starIndex}
          className="fa-solid fa-star"
          style={{ color: "#FFD43B" }}
        ></i>
      ))}
    </Paragraph>
    {/* Buttons Section */}
<Row gutter={16} style={{ marginTop: "20px" }}>
  <Col
    xs={24}
    md={12}
    style={{
      display: "flex",
      justifyContent: "end",
    }}
  >
    <Button
      type="primary"
      style={{
        backgroundColor: "green",
        color: "white",
        width: "100%", // Reduced button width
        marginBottom: "10px",
      }}
      onClick={() =>
        navigate(`/createNewCourse-dashboard/${course?.id}/lesson`)
      }
    >
      Add New Content
    </Button>
  </Col>
  <Col
    xs={24}
    md={12}
    style={{
      display: "flex",
      justifyContent: "start",
    }}
  >
    <Button
      type="primary"
      style={{
        backgroundColor: "green",
        color: "white",
        width: "100%", // Reduced button width
        marginBottom: "20px",
      }}
      onClick={() =>
        navigate(`/createNewCourse-dashboard/${course?.id}/quiz`)
      }
    >
      Add Course Quiz
    </Button>
  </Col>
</Row>
  </Col>
</Row>





          {/* Lessons */}
          <div className="mt-5">
            <Title level={4} style={{ fontSize: isSmallScreen ? "16px" : "20px" }}>
              Lessons
            </Title>
            <table className="table text-center">
              <thead>
                <tr>
                  <th>Lesson Title</th>
                  <th>Lesson Description</th>
                  <th>Lesson Duration</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {lessons?.map((lesson, index) => (
                  <tr key={index}>
                    <td>{lesson.title}</td>
                    <td>{lesson.description}</td>
                    <td>{lesson.duration} Min</td>
                    <td>
                      <Link
                        to={`/createNewCourse-dashboard/${course.id}/lesson/${lesson.id}/quiz`}
                      >
                        <button className="btn bg-color text-white">
                          Add Quiz
                        </button>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* Quizzes Table */}
          <div className="mt-5">
              <Title level={4}>Quizzes</Title>
              <table className="table text-center">
                <thead>
                  <tr>
                    <th className="col">Quiz Title</th>
                    <th className="col">Due Date From Enroll</th>
                    <th className="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {quizzes?.map((quiz, index) => (
                    <tr key={index}>
                      <td>{quiz.title}</td>
                      <td>{quiz.dueDate}</td>
                      <td>
                        <Link to={`/courseDetails-dashboard/${course.id}/quizDetails/${quiz.id}`}>
                          <button className="btn bg-color text-white">View</button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
        </Card>
      </div>
    </div>
  );
};

export default CourseDetails;
