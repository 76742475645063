export default function LessonContent({ selectedLesson, videoUrl, loadingLesson, loadingVideo, handleError }) {
  if (loadingLesson) return <div className="w-100 d-flex justify-content-center align-items-center fs-3">Loading lesson...</div>;
  if (handleError) return <div className="w-100 d-flex justify-content-center align-items-center fs-3">{handleError}</div>;
  if (!selectedLesson) return <div className="w-100 d-flex justify-content-center align-items-center fs-3">Select a lesson to view details.</div>;

  return (
    <div className="lesson-content">
      <h2 className="text-black">{selectedLesson.title}</h2>
      
      {loadingVideo ? (
          <div>Loading video...</div>
        ) : (
            videoUrl && <video src={videoUrl} controls width="100%" />
        )}
        <p>{selectedLesson.description}</p>
    </div>
  );
}
