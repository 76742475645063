import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { BaseURL } from '../../../api/BaseURL';

const Reports = () => {
  const token = localStorage.getItem("token_Value");
  const [report, setReport] = useState([]);

  const getReport = () => {
    axios.get(BaseURL + `courses/report`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      setReport(response.data.report);
      console.log(response.data.report);
    }).catch((error) => {
      console.log(error);
      // setError(error.message)
    });
  };

  useEffect(() => {
    getReport();
  }, []);

  const list = [
    {
    month: 'Sep 2024',
    study: '80%',
    performance: '90%',
    feedback: '4.5/5',
    total: '86.67'
  },
    {
    month: 'Sep 2024',
    study: '80%',
    performance: '90%',
    feedback: '4.5/5',
    total: '86.67'
  },
    {
    month: 'Sep 2024',
    study: '80%',
    performance: '90%',
    feedback: '4.5/5',
    total: '86.67'
  },
]
  return (
    <div className="row my-4">
      <div className="col-md-12">
        <table className='table table-striped'>
          <thead>
            <tr>
              <th>Year</th>
              <th>Month</th>
              <th>Score</th>
              {/* <th>Feedback</th>
              <th>Total</th> */}
            </tr>
          </thead>
          <tbody>
            {report.map((item, index) => (
              <tr key={index}>
                <td>{item.year}</td>
                <td>{item.month}</td>
                <td>{item.score}</td>
                {/* <td>{item.feedback}</td>
                <td>{item.total}</td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Reports