import React, { useEffect, useState } from 'react';
import { useDarkMode } from '../Contexts/DarkModeContext';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { BaseURL } from '../../api/BaseURL';
import axios from 'axios';
import Stars from '../Stars/Stars';
import { useSelector } from 'react-redux';
import { Loader } from '@googlemaps/js-api-loader';

const CourseView = () => {
    const { darkMode } = useDarkMode();
    const { courseId } = useParams();
    const { user } = useSelector((state) => state.auth);
    const isScreenSmall = useMediaQuery({ maxWidth: 576 });
    const [course, setCourse] = useState(null);
    const [enrollCourses, setEnrollCourses] = useState([]);
    const [lessons, setLessons] = useState(null);
    const token = localStorage.getItem("token_Value");
    const navigate = useNavigate();

    useEffect(() => {
        getCourse();
        getEnrollCourses();
        getLessons();
    }, [courseId]);

    const getCourse = () => {
        axios.get(BaseURL + `courses/${courseId}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            setCourse(response.data.course);
            console.log(response.data.course);
        }).catch((error) => console.log(error));
    };

    const getEnrollCourses = () => {
        axios.get(BaseURL + `courses/my_courses`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            setEnrollCourses(response.data.courses || []);
            console.log(response.data);
        }).catch((error) => console.log(error));
    };

    const getLessons = () => {
        axios.get(`${BaseURL}courses/${courseId}/lessons`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        }).then((response) => {
            setLessons(response.data.lessons);
        }).catch((error) => console.log(error));
    };

    const handleEnroll = () => {
        axios.post(
            `${BaseURL}courses/${courseId}`,
            {},
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        )
            .then(() => navigate(`/courses/${courseId}/lessons`))
            .catch((error) => console.log(error));
    };

    // Check if the user is enrolled in this course
    const isEnrolled = enrollCourses.some(enrolledCourse => enrolledCourse.id === parseInt(courseId));

    return (
        <div className={`${darkMode ? "bg-dark text-light" : "bg-white text-dark"}`}>
            {course && (
                <div className='container py-5'>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex align-items-center font-sm text-color">
                                <i className="fa-solid fa-table-cells-large me-1"></i>
                                <p>{course.category}</p>
                            </div>
                            <h1>{course.name}</h1>
                            <Stars rating={course.rate} />
                            <p className='my-1 fs-5'>Level: <span className='text-color fw-medium'>{course.level}</span></p>
                            <p className='my-1 fs-5'>Duration: <span className='text-color fw-medium'>{course.duration} Hours</span></p>
                            <p className='my-1 fs-5'>Sections: <span className='text-color fw-medium'>{course.sections}</span></p>
                            <p className='my-1 fs-5'>Lessons: <span className='text-color fw-medium'>{course.lessonsCount}</span></p>
                            <p className='my-1 fs-5'>Price: <span className='text-color fw-medium'>${course.price}</span></p>
                        </div>
                        <div className="col-md-6 d-flex justify-content-center">
                            <div className='w-75'>
                                <img src={course.image} alt="Course" className="img-fluid w-100" />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <p className='my-3 fs-4'>{course.description}</p>
                        </div>
                        <div className="col-md-12">
                            <h2>Course Content :</h2>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Lesson Title</th>
                                        <th>Lesson Description</th>
                                        <th>Lesson Duration</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {lessons?.map((lesson, index) => (
                                        <tr key={index}>
                                            <td>{lesson.title}</td>
                                            <td>{lesson.description}</td>
                                            <td>{lesson.duration} Min</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="col-md-12 d-flex justify-content-end">
                            {user.subscription === 'free' ? (
                                <Link to={'/pricing'} className="btn bg-color text-white rounded-5 px-4">
                                    Enroll Now
                                </Link>
                            ) : (
                                isEnrolled && user.subscription !== 'free' ? (
                                    <Link to={`/courses/${courseId}/lessons`} className="btn bg-color text-white rounded-5 px-4">
                                        View Course
                                    </Link>
                                ) : (
                                    <button className="btn bg-color text-white rounded-5 px-4" onClick={handleEnroll}>
                                        Enroll Now
                                    </button>
                                )
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CourseView;
