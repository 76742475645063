import React, { useEffect, useState } from 'react';
import { useDarkMode } from '../../Contexts/DarkModeContext';
import Performance from '../../CircularPerformance/CircularPerformance';
import { BaseURL } from "../../../api/BaseURL";
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import './Assignment.css';

const Assignments = () => {
  const [courses, setCourses] = useState([]);
  const [quizzes, setQuizzes] = useState([]);
  const { darkMode } = useDarkMode();
  const token = localStorage.getItem("token_Value");
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  // Fetch courses for the student
  const fetchCourses = async () => {
    try {
      const response = await axios.get(`${BaseURL}courses/my_courses`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      console.log('API Response for Courses:', response.data);

      if (Array.isArray(response.data.courses)) {
        setCourses(response.data.courses);
        fetchQuizzesForCourses(response.data.courses);
      } else {
        console.error('Expected an array of courses, but received:', response.data.courses);
      }
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };

  // Fetch quizzes for each course and flatten the quizzes into a single array
  const fetchQuizzesForCourses = async (courses) => {
    const quizzesData = [];

    for (const course of courses) {
      try {
        const response = await axios.get(`${BaseURL}courses/${course.id}/quizzes`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        console.log(`Quizzes for course ${course.id}:`, response.data);

        // Check if quizzes exist and are in the expected format
        if (Array.isArray(response.data) && response.data.length > 0) {
          for (const quiz of response.data) {
            const score = await fetchQuizScore(course.id, quiz.id);
            quizzesData.push({ ...quiz, courseId: course.id, score });
          }
        } else if (response.data.quizzes && response.data.quizzes.length > 0) {
          for (const quiz of response.data.quizzes) {
            const score = await fetchQuizScore(course.id, quiz.id);
            quizzesData.push({ ...quiz, courseId: course.id, score });
          }
        } else {
          console.log(`No quizzes found for course ${course.id}`);
        }
      } catch (error) {
        console.error(`Error fetching quizzes for course ${course.id}:`, error);
      }
    }

    setQuizzes(quizzesData);
    console.log("Final quizzesData structure:", quizzesData);
  };

  // Fetch score for a specific quiz
  const fetchQuizScore = async (courseId, quizId) => {
    try {
      const response = await axios.get(`${BaseURL}courses/${courseId}/quiz/${quizId}/score`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      return response.data.score || 'N/A';
    } catch (error) {
      console.error(`Error fetching score for quiz ${quizId} in course ${courseId}:`, error);
      return 'N/A';
    }
  };

  // Handle pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentQuizzes = quizzes.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    fetchCourses();
  }, []);

  useEffect(() => {
    // Log quizzes to verify it's updating correctly
    console.log("Updated quizzes state:", quizzes);
  }, [quizzes]);

  // Determine the status of a quiz based on due date and completion
  const getQuizStatus = (quiz) => {
    if (quiz.score && quiz.score !== 'N/A') {
      return { text: 'Completed', color: 'text-success' };
    } else if (quiz.dueDate && new Date(quiz.dueDate) < new Date()) {
      return { text: 'Late', color: 'text-danger' };
    } else {
      return { text: 'In Progress', color: 'text-warning' };
    }
  };

  // Navigate to courseId and quizId page
  const handleAssignmentClick = (courseId, quizId) => {
    navigate(`/user/${courseId}/${quizId}`);
};

  return (
    <>
      <div className="row">
        <div className='col-md-9'>
          {/* Quizzes Table */}
          <div className='border border-success p-3 my-5 rounded'>
            <h2 className='text-color'>Assignments List</h2>
            <div className="table-responsive">
              <table className="table table-borderless table-striped">
                <thead className="text-center">
                  <tr>
                    {/* <th scope="col">Course Name</th> */}
                    <th scope="col">Quiz Title</th>
                    <th scope="col">Status</th>
                    <th scope="col">Quiz Date</th>
                    {/* <th scope="col">Score</th> */}
                  </tr>
                </thead>
                <tbody className="text-center">
                  {currentQuizzes.length > 0 ? (
                    currentQuizzes.map((quiz) => {
                      const status = getQuizStatus(quiz);
                      return (
                        <tr
                          key={quiz.id}
                          className={`border-bottom border-success`}
                          onClick={() => handleAssignmentClick(quiz.courseId, quiz.id)}
                          style={{ cursor: 'pointer' }}
                        >
                          {/* <td>{quiz.courseId}</td> */}
                          <td>{quiz.title}</td>
                          <td className={status.color}>{status.text}</td>
                          <td>{quiz.dueDate ? new Date(quiz.dueDate).toLocaleDateString() : 'N/A'}</td>
                          {/* <td>{quiz.score}</td> */}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="5">No quizzes available.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          
          {/* Pagination Controls */}
          <nav>
            <ul className="pagination justify-content-center">
              {[...Array(Math.ceil(quizzes.length / itemsPerPage))].map((_, index) => (
                <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                  <button
                    className="page-link bg-transparent text-color"
                    onClick={() => paginate(index + 1)}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Assignments;
