import React, { useEffect, useState, useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import { useDarkMode } from '../../Contexts/DarkModeContext';
import { BaseURL } from '../../../api/BaseURL';
import axios from 'axios';
import img1 from '../../../Assets/man1 (1).jpg';
import Style from '../../RightProfile/RightProfile.module.css';
import { IoShieldCheckmarkSharp } from 'react-icons/io5';

const Overview = ({ user }) => {
  const isScreenSmall = useMediaQuery({ minWidth: 0, maxWidth: 768 });
  const { darkMode } = useDarkMode();
  const token = localStorage.getItem("token_Value");

  const [tasks, setTasks] = useState([]);
  const [courses, setCourses] = useState([]);
  const [newTask, setNewTask] = useState({ text: '', dueDate: '', category: '' });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const fetchCourses = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BaseURL}courses/uncompleted/`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const transformedCourses = response.data.courses.map(course => ({
        ...course,
        coverdLessons: course.coverdLessons.split(','),
      }));
      setCourses(transformedCourses);
    } catch (error) {
      console.error('Error fetching courses:', error);
      setError('Failed to load courses.');
    } finally {
      setLoading(false);
    }
  }, [token]);

  const fetchTasks = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BaseURL}users/tasks`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (Array.isArray(response.data.tasks)) {
        setTasks(response.data.tasks);
        console.log('Fetched tasks:', response.data); // Debugging log
      } else {
        console.error('Unexpected data structure for tasks:', response.data);
        setError('Failed to load tasks.');
        setTasks([]); // Reset to empty array if data structure is unexpected
      }
    } catch (error) {
      console.error('Error fetching tasks:', error);
      setError('Failed to load tasks.');
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    fetchCourses();
    fetchTasks();
  }, [fetchCourses, fetchTasks]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTask((prevTask) => ({ ...prevTask, [name]: value }));
  };

  const handleCreateTask = async () => {
    if (!newTask.text || !newTask.dueDate || !newTask.category) {
        alert("Please fill in all fields.");
        return;
    }
    try {
        // Assuming your API returns the newly created task with an ID and possibly a default `completed` status
        const response = await axios.post(`${BaseURL}users/task`, {
            ...newTask,
            completed: false, // Set the initial completed status
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        
        console.log('Created task:', response.data); // Debugging log
        // Add the new task to the existing tasks state
        setTasks((prevTasks) => [
            ...prevTasks,
            { ...newTask, id: response.data.task[0], completed: false } // Add the new task with a default `completed` value
        ]);
        setNewTask({ text: '', dueDate: '', category: '' }); // Reset the input fields
    } catch (error) {
        console.error('Error creating task:', error);
    }
};


  const handleToggleTask = async (taskId) => {
    console.log('Toggling task with ID:', taskId); // Debugging log
    const updatedTask = tasks.find((task) => task.id === taskId);
    console.log('Found task for toggle:', updatedTask); // Debugging log

    if (updatedTask) {
        try {
            // Toggle the completed status
            const newCompletedStatus = !updatedTask.completed;

            // Update the task status on the server
            await axios.put(`${BaseURL}users/tasks/${taskId}`, {
                ...updatedTask,
                completed: newCompletedStatus,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            // Now update the local state to reflect the change
            setTasks((prevTasks) =>
                prevTasks.map((task) => 
                    task.id === taskId ? { ...task, completed: newCompletedStatus } : task
                )
            );

        } catch (error) {
            console.error('Error updating task status:', error);
        }
    } else {
        console.error('Task not found for ID:', taskId);
    }
};


  

  return (
    <>
      <div className="row">
        <div className="col-md-9">
          <div className={`row justify-content-evenly my-3 text-black ${isScreenSmall ? "gy-3" : ""}`}>
            {!courses.length>0 ? <p className={`text-center ${darkMode?'text-white':'text-black'}`}>You are not enrolled in any courses yet.</p> : courses.slice(0, 3).map((course, index) => (
              <div key={index} className={`col-md-3 py-4 px-1 rounded-4 ${isScreenSmall ? "w-75" : ""}`} style={{ backgroundColor: '#EFF7E2' }}>
                <p className={`fw-normal fs-6 my-3 ${isScreenSmall ? "ms-2" : "text-center"}`}>
                  {course.name}
                </p>
                <div className="d-flex py-1 rounded-2 justify-content-evenly" style={{ backgroundColor: "#FCF9FF" }}>
                  Uncompleted Lessons: {course.coverdLessons.length}
                </div>
              </div>
            ))}
          </div>
            <div>
              <h2 className="fw-bold fs-3 mt-5 mb-3">Your Tasks</h2>
            {Array.isArray(tasks) && tasks.length>0 ? tasks?.map((task) => (
              <div key={task.id} className="my-3">
                <div className="d-flex align-items-center">
                  <input type="checkbox" checked={task?.completed} onChange={() => handleToggleTask(task.id)} />
                  <p className={`m-0 small-font text-bg-dark-subtle fw-bold ms-1 ${task.completed ? 'text-decoration-line-through' : ''}`}>{task?.text}</p>
                </div>
                <div className="d-flex small-font justify-content-between mx-3 py-1 border-bottom">
                  <p>{task.category}</p>
                  <p className="text-gold fw-bold">{new Date(task?.dueDate).toLocaleDateString()}</p>
                </div>
              </div>
            )):<p>No tasks found yet</p>}
            </div>
        </div>
        <div className="col-md-3">
          <h5 className="text-center fw-bold">Profile</h5>
          <div className="text-center d-flex flex-column align-items-center">
            <img src={BaseURL+user.image} className={`w-50 rounded-pill p-2 ${Style.border}`} alt="" />
            <div className="d-flex justify-content-center align-items-center">
              <p className="fw-bold">{user?.firstName} {user?.lastName}</p>
              <IoShieldCheckmarkSharp className="text-color mx-2" />
            </div>
            <p className="small-font fw-bold">{user?.role}</p>
            <div style={{ backgroundColor: "#F8F8F8" }} className={`rounded-4 py-1 text-black ${isScreenSmall ? "w-75 mx-auto mt-3" : ""}`}>
              <div className="d-flex small-font justify-content-around my-2 rounded-3">
                {/* <p>November 2023</p> */}
              </div>
            </div>
          </div>
          <div className={`border-top mt-3 ${isScreenSmall ? 'mx-5' : ''}`}>
            <p className="fw-bold mb-3">ToDo List</p>
            <div className="mb-3">
              <input type="text" name="text" placeholder="Task description" value={newTask.text} onChange={handleInputChange} className="form-control my-1" />
              <input type="text" name="category" placeholder="Category" value={newTask.category} onChange={handleInputChange} className="form-control my-1" />
              <input type="datetime-local" name="dueDate" value={newTask.dueDate} onChange={handleInputChange} className="form-control my-1" />
              <button onClick={handleCreateTask} className="btn bg-color">Add Task</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
