import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { BaseURL } from "../../api/BaseURL";

export default function Verify() {
  const { t } = useTranslation();
  const location = useLocation();
  const [statusMessage, setStatusMessage] = useState(t("verify.sendingEmail"));

  useEffect(() => {
    const sendVerificationEmail = async () => {
      try {
        const email = location.state?.email; // Assuming the email is passed via location state
        if (!email) {
          setStatusMessage(t("verify.noEmailProvided"));
          return;
        }

        // Perform the PUT request to send the verification email
        const response = await axios.put(`${BaseURL}users/verifyEmail`, { email });
        if (response.status === 200) {
          setStatusMessage(t("verify.emailSentSuccess"));
        } else {
          setStatusMessage(t("verify.unexpectedError"));
        }
      } catch (error) {
        console.error("Error sending verification email:", error);
        setStatusMessage(t("verify.emailSentFailure"));
      }
    };

    sendVerificationEmail();
  }, [location.state, t]);

  return (
    <div className="my-5 py-5">
      <div className="container h-100 my-5 py-5">
        <h1>{t("verify.title")}</h1>
        <p>{statusMessage}</p>
        <p>{t("verify.instructions")}</p>
        <Link to="/login" className="text-color fw-bold">
          {t("verify.loginNow")}
        </Link>
      </div>
    </div>
  );
}
