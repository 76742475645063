import React, { useState } from "react";
import { Upload, Button, Typography, message } from "antd";
import { UploadOutlined, SaveOutlined } from "@ant-design/icons";
import Navbar from "../DashboardNavbar/DashboardNavbar";
import { useNavigate, useParams } from "react-router-dom";
import { BaseURL } from "../../api/BaseURL";
import axios from "axios";
import { useMediaQuery } from "react-responsive";

const { Title } = Typography;

const CreateNewContent = () => {
  const { courseId } = useParams();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery({ maxWidth: 768 });
  const [formData, setFormData] = useState({
    title: "",
    duration: "",
    description: "",
    video: "",
    access: "",
  });

  function getData(e) {
    let data = { ...formData };
    if([e.target.name]=='video'){
      data[e.target.name] = e.target.files[0];
    }else{
      data[e.target.name] = e.target.value;
    }
    setFormData(data);
    console.log(data);
  }

  function handleSubmit(e){
    e.preventDefault();
    const token = localStorage.getItem("token_Value")
    axios.post(BaseURL + `courses/${courseId}/lesson`,formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    }).then((response)=>{
      console.log('data sent',response)
      message.success('Lesson was added successfully');
      navigate(`/courseDetails-dashboard/${courseId}`);
    }).catch((err)=>{
      console.log(err)
    })
  }

  return (
    <div className="row" style={{ backgroundColor: "#F6FAFB" }}>
      {/* Sidebar */}
      <div
        className="col-md-2"
        style={{
          backgroundColor: "#F6FAFB",
          padding: "20px",
        }}
      >
        {/* <Navbar /> */}
      </div>

      {/* Main Content */}
      <div className="col-md-10 px-5" style={{ padding: "20px" }}>
        {/* Dashboard Title */}
        <div
          className="d-flex align-items-center"
          style={{ paddingBottom: "10px" }}
        >
          <Button
              type="text"
              onClick={() => navigate(-1)} // Or use navigate(-1) for going back to previous page
              style={{ marginRight: "10px" }}
            >
              {"< Back"}
            </Button>
            <div className="w-100 d-flex justify-content-center align-items-end">
                        <h1
                            className="m-0"
                            style={{
                                fontSize: isSmallScreen ? "18px" : "28px",
                                fontWeight: "600",
                            }}
                        >
                            Dashboard
                        </h1>
                        <p
                            className="ml-3 text-muted"
                            style={{
                                marginLeft: "10px",
                                fontSize: isSmallScreen ? "12px" : "16px",
                            }}
                        >
                            / Create New Content
                        </p>
                    </div>
        </div>

        {/* Content Section */}
        <div
          className="create-content-page mt-4"
          style={{
            backgroundColor: "#fff",
            padding: "40px",
            borderRadius: "8px",
            boxShadow: "0 1px 4px rgba(0,0,0,0.1)",
            width: "100%",
            maxWidth: "800px",
            margin: "0 auto",
          }}
        >
          <Title
            level={3}
            style={{ marginBottom: "30px", textAlign: "center" }}
          >
            Create New Content
          </Title>

          {/* Upload Section */}
          <form onSubmit={handleSubmit}>
            <div className="row g-3">
              <div className="col-md-6">
                <div className="w-100">
                  <label htmlFor="title" className="form-label">Lesson Title</label>
                  <input
                    type="text"
                    className="form-control text-gray-700 appearance-none border  rounded-md"
                    id="title"
                    name="title"
                    placeholder="Enter lesson title"
                    onChange={getData}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="w-100">
                  <label htmlFor="duration" className="form-label">Lesson Duration <span className="fs-6 text-secondary">(inMinutes)</span></label>
                  <input
                    type="number"
                    className="form-control text-gray-700 appearance-none border  rounded-md"
                    id="duration"
                    name="duration"
                    placeholder="Enter lesson duration"
                    onChange={getData}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="w-100">
                  <label htmlFor="access" className="form-label">Lesson Access</label>
                  <select name="access" id="access" className="form-control appearance-none border  rounded-md" onChange={getData}>
                    <option value="">Select Access</option>
                    <option value="private">Private</option>
                    <option value="public">Public</option>
                  </select>
                </div>
              </div>
              
              
              <div className="col-md-6">
                <div className="w-100">
                  <label htmlFor="video" className="form-label">Lesson Video</label>
                  <input
                    type="file"
                    className="form-control text-gray-700 appearance-none border rounded-md"
                    id="video"
                    name="video"
                    accept="video/*"
                    placeholder="Enter lesson video"
                    onChange={getData}
                    required
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="w-100">
                  <label htmlFor="description" className="form-label">Lesson Description</label>
                  <textarea
                    className="form-control text-gray-700 appearance-none border rounded-md"
                    id="description"
                    name="description"
                    placeholder="Enter lesson description"
                    onChange={getData}
                    required
                  ></textarea>
                </div>
              </div>
              <div className="col-md-12">
                <div className="w-100">
                  <button type="submit" className="btn bg-color me-2">Save</button>
                  <button type="reset" className="btn btn-secondary ms-2">Cancel</button>
                </div>
              </div>
            </div>
          </form>
          {/* <div
            style={{
              border: "2px dashed gray",
              borderRadius: "8px",
              padding: "40px",
              textAlign: "center",
              marginBottom: "20px",
              backgroundColor: "#fafafa",
            }}
          >
            <Upload>
              <Button icon={<UploadOutlined />} type="primary">
                Add New Picture or Video
              </Button>
            </Upload>
          </div>

          {/* Text Area for Content */}
          {/* <textarea
            style={{
              width: "100%",
              height: "150px",
              padding: "10px",
              borderRadius: "8px",
              border: "1px solid #ccc",
              fontSize: "16px",
              marginBottom: "20px",
              boxShadow: "inset 0 1px 4px rgba(0,0,0,0.1)",
            }}
            placeholder="Start Typing..."
          /> */}

          {/* Save & Publish Button */}
          {/* <div style={{ textAlign: "center" }}>
            <Button
              type="primary"
              icon={<SaveOutlined />}
              style={{
                backgroundColor: "green",
                color: "white",
                padding: "10px 30px",
                borderRadius: "8px",
                fontSize: "16px",
              }}
            >
              Save & Publish
            </Button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default CreateNewContent;
