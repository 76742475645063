import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import DashboardNavBar from '../DashboardNavbar/DashboardNavbar';
import { Typography, Card, List,Button } from "antd";
import { useMediaQuery } from "react-responsive";
import { BaseURL } from "../../api/BaseURL";

const { Title, Paragraph, Text } = Typography;

const QuizDetails = () => {
  const { quizId, courseId } = useParams();
  const token = localStorage.getItem("token_Value");
  const [quiz, setQuiz] = useState(null);
  const isSmallScreen = useMediaQuery({ maxWidth: 768 });
  const navigate = useNavigate();

  const getQuizDetails = () => {
    axios
      .get(`${BaseURL}courses/${courseId}/quiz/${quizId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setQuiz(response.data.quiz);
      })
      .catch((error) => {
        console.error("Error fetching quiz details:", error);
      });
  };

  useEffect(() => {
    if (quizId) {
      getQuizDetails();
    }
  }, [quizId]);

  return (
    <div style={{ padding: "30px", backgroundColor: "#f0f2f5", minHeight: "100vh" }}>
      <div><DashboardNavBar/></div>
      <Button
                        type="text"
                        onClick={() => navigate(-1)} // Or use navigate(-1) for going back to previous page
                        style={{ marginRight: "10px" }}
                    >
                        {"< Back"}
                    </Button>
      <Card
        style={{
          backgroundColor: "#fff",
          padding: "30px",
          borderRadius: "8px",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
          maxWidth: "800px",
          margin: "auto",
        }}
      >
        {quiz ? (
          <>
            <Title level={2} style={{ textAlign: "center", color: "#008772" }}>{quiz.title}</Title>
            <Paragraph style={{ textAlign: "center" }}>
              <strong>Due Date:</strong> {quiz.dueDate}
            </Paragraph>
            <Paragraph style={{ textAlign: "center", marginBottom: "40px" }}>
              <strong>Course ID:</strong> {quiz.courseId} | <strong>Lesson ID:</strong> {quiz.lessonId || "N/A"}
            </Paragraph>
            
            <Title level={3} style={{ color: "#595959", marginBottom: "20px" }}>Questions</Title>
            <List
              dataSource={quiz.questions}
              renderItem={(question, index) => (
                <Card
                  key={question.id}
                  style={{
                    marginBottom: "20px",
                    padding: "20px",
                    borderRadius: "8px",
                    border: "1px solid #d9d9d9",
                    backgroundColor: "#fafafa",
                  }}
                >
                  <Text strong style={{ fontSize: "16px" }}>
                    Question {index + 1}: {question.text}
                  </Text>
                  <List
                    dataSource={question.answers}
                    renderItem={(answer) => (
                      <List.Item
                        key={answer.id}
                        style={{
                          padding: "10px 20px",
                          borderRadius: "4px",
                          backgroundColor: answer.isCorrect ? "#e6ffed" : "#ffffff",
                          color: answer.isCorrect ? "#389e0d" : "#595959",
                          border: answer.isCorrect ? "1px solid #b7eb8f" : "1px solid #d9d9d9",
                          marginBottom: "10px",
                          cursor: "pointer",
                          transition: "background 0.3s",
                        }}
                      >
                        <Text>{answer.text}</Text>
                        {answer.isCorrect && <Text style={{ marginLeft: "10px", color: "#52c41a" }}>(Correct)</Text>}
                      </List.Item>
                    )}
                  />
                </Card>
              )}
            />
          </>
        ) : (
          <Paragraph style={{ textAlign: "center" }}>Loading quiz details...</Paragraph>
        )}
      </Card>
    </div>
  );
};

export default QuizDetails;
