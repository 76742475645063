import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BaseURL } from '../../api/BaseURL';
import { useMediaQuery } from 'react-responsive';
import Style from "../FifthSection/FifthSection.module.css";
import man2 from "../../Assets/man2.jpg";
import { useDarkMode } from '../Contexts/DarkModeContext';
import { Link, useParams } from 'react-router-dom';
import Stars from '../Stars/Stars';

const CoursesByCategory = () => {
    const { darkMode } = useDarkMode();
    const { category } = useParams();
    const isScreenSmall = useMediaQuery({ maxWidth: 576 });
    const [courses, setCourses] = useState(null);
    const token = localStorage.getItem("token_Value");

    const getCourses = () => {
        axios.get(BaseURL + `courses/category/${category}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            console.log(response.data);
            setCourses(response.data.courses);
        }).catch((error) => {
            console.log(error);
            // setError(error.message)
        });
    };

    useEffect(() => {
        getCourses();
    }, [category]);  // Add 'category' as a dependency to update on category change

    return (
        <div className='container'>
            <div className="row">
                <h3 className="fw-bold mt-5">
                    The courses in <span className="text-color">{category}</span>
                </h3>

                {courses && courses.length > 0 ? (
                    courses.map((course) => (
                        <div className="col-md-3 my-5" key={course.title}>

                            <Link to={`/courses/${course.id}`}><div className="bg-white rounded-4 p-3  shadowbox">
                                <div className="d-flex align-items-center font-sm gray-text mb-2">
                                    <i class="fa-solid fa-signal me-1"></i>
                                    <p>{course.level}</p>
                                </div>
                                <div style={{ height: '200px' }}>
                                    <img src={course.image} className="w-100 h-100 rounded-3" alt={course.title} />
                                </div>
                                <div className="d-flex justify-content-between mt-3 mb-1 gray-text">
                                    <div className="d-flex align-items-center font-sm">
                                        <i className="fa-solid fa-table-cells-large me-1"></i>
                                        <p>{course.category}</p>
                                    </div>
                                    <div className="d-flex align-items-center font-sm">
                                        <i className="fa-regular fa-clock me-1"></i>
                                        <p>{course.duration} Hr</p>
                                    </div>
                                </div>
                                <Stars rating={course.rate} />
                                <h5 className="fw-bold">{course.title}</h5>
                                <p className="font-sm gray-text mt-4">{course.description.length > 30
                                    ? `${course.description.slice(0, 30)}...`
                                    : course.description}</p>
                                <div className="d-flex mt-3">
                                    <div className="d-flex w-75 align-items-center">
                                        {/* <img src={course.instructorImage} className="w-25 rounded-pill" alt={course.instructor} /> */}
                                        <p className="font-sm fw-bold ms-2">{course.user.firstName}</p>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-end w-100">
                                        {/* <del className="gray-text font-sm me-2"><em>{course.price}</em></del> */}
                                        <h6 className="fw-bold text-color m-0">${course.price}</h6>
                                    </div>
                                </div>
                            </div></Link>
                        </div>

                    ))
                ) : (
                    <div className="col-12 text-center my-5">
                        <h5 className="fw-bold my-5">No courses available for the category "{category}"</h5>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CoursesByCategory;
