import React, { useEffect, useState } from "react";
import { Button, Card, message, Modal } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import Navbar from "../DashboardNavbar/DashboardNavbar";
import { useNavigate } from "react-router-dom";
import img from "../../Assets/datascience.jpg";
import axios from "axios";
import { BaseURL } from "../../api/BaseURL";
import { useMediaQuery } from "react-responsive";
import DashboardNavbar from '../DashboardNavbar/DashboardNavbar'

const AllEvents = () => {
  const navigate = useNavigate();
  const [courses, setCourses] = useState(null);
  const token = localStorage.getItem("token_Value");
  const isSmallScreen = useMediaQuery({ maxWidth: 768 });
  const getCourses = () => {
    axios
      .get(`${BaseURL}courses/`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setCourses(response.data.courses);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDelete = (courseId) => {
    Modal.confirm({
      title: "Are you sure you want to delete this course?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => {
        axios
          .delete(`${BaseURL}courses/${courseId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(() => {
            message.success("Course deleted successfully");
            setCourses(courses.filter((course) => course.id !== courseId));
          })
          .catch((error) => {
            message.error("Failed to delete course");
            console.error(error);
          });
      },
    });
  };

  const handleEdit = (courseId) => {
    navigate(`/editCourse-dashboard/${courseId}`);
  };

  useEffect(() => {
    getCourses();
  }, []);

  return (
    <>
      {courses !== null ? (
        <div className="row" style={{ backgroundColor: "#F6FAFB" }}>
          <div className="col-md-2" style={{ backgroundColor: "#F6FAFB", padding: "20px" }}>
            <DashboardNavbar />
          </div>
          <div className="col-md-10 px-5" style={{ padding: "20px" }}>
            <div className="w-100 d-flex justify-content-center align-items-end">
              <h1
                className="m-0"
                style={{
                  fontSize: isSmallScreen ? "18px" : "28px",
                  fontWeight: "600",
                }}
              >
                Dashboard
              </h1>
              <p
                className="ml-3 text-muted"
                style={{
                  marginLeft: "10px",
                  fontSize: isSmallScreen ? "12px" : "16px",
                }}
              >
                / Events
              </p>
            </div>

            <div className="d-flex justify-content-between align-items-center mt-4" style={{ backgroundColor: "#fff", padding: "15px 25px", borderRadius: "12px", boxShadow: "0 4px 12px rgba(0,0,0,0.1)" }}>
              <Button type="primary" icon={<PlusCircleOutlined />} style={{ backgroundColor: "#28a745", color: "white", borderRadius: "8px", padding: "8px 20px", fontWeight: "600" }} onClick={() => navigate("/addEvent")}>
                Add New Event
              </Button>
            </div>

            <div className="courses-container mt-5" style={{ display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))", gap: "24px", padding: "20px", backgroundColor: "#f9f9f9", borderRadius: "12px", boxShadow: "0 1px 6px rgba(0,0,0,0.1)" }}>
              {courses.map((course) => (
                <Card
                  key={course.id}
                  hoverable
                  cover={<img alt={course.name} src={course?.image} style={{ height: "160px", objectFit: "cover", borderRadius: "8px 8px 0 0" }} />}
                  style={{ width: "100%", borderRadius: "12px", boxShadow: "0 4px 10px rgba(0,0,0,0.08)" }}
                  actions={[
                    <EditOutlined key="edit" onClick={() => handleEdit(course.id)} />,
                    <DeleteOutlined key="delete" onClick={() => handleDelete(course.id)} />,
                    <EllipsisOutlined key="ellipsis" />,
                  ]}
                >
                  <Card.Meta title={course.name} description={course.description.length > 30
                    ? `${course.description.slice(0, 30)}...`
                    : course.description} style={{ textAlign: "center" }} />
                  <div style={{ marginTop: "16px", textAlign: "center" }}>
                    <Button
                      type="primary"
                      style={{ backgroundColor: "#28a745", color: "white", borderRadius: "8px", fontWeight: "600" }}
                      onClick={() => navigate(`/courseDetails-dashboard/${course.id}`)}
                    >
                      Event Details
                    </Button>
                  </div>
                </Card>
              ))}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default AllEvents;
