import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Result, Button } from 'antd';

const FailedPayment = () => {
  const [countdown, setCountdown] = useState(5);
  const navigate = useNavigate();

  useEffect(() => {
    // Countdown timer logic
    const timer = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    // Redirect to the home page after 5 seconds
    const redirectTimer = setTimeout(() => {
      navigate('/');
    }, 5000);

    return () => {
      clearInterval(timer);
      clearTimeout(redirectTimer);
    };
  }, [navigate]);

  return (
    <div className="container text-center" style={{ marginTop: "50px", marginBottom: "150px" }}>
      <Result
        status="error"
        title="Payment Failed"
        subTitle="We couldn't process your payment. Redirecting you to the home page shortly."
        extra={[
          <Button 
            type="" 
            className='text-white bg-color'
            key="home" 
            onClick={() => navigate('/')}
          >
            Go to Home Now
          </Button>,
        ]}
      />
      <p style={{ marginTop: "20px" }}>
        Redirecting in <strong>{countdown}</strong> seconds...
      </p>
    </div>
  );
};

export default FailedPayment;
