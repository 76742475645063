import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { BaseURL } from '../../api/BaseURL';
import { Card, Button, Spin, Result, Typography, message } from 'antd';
import './QuizPage.css';

const { Title, Paragraph } = Typography;

function QuizPage() {
  const { quizId, courseId } = useParams();
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [showError, setShowError] = useState(false);
  const [result, setResult] = useState(null);
  const token = localStorage.getItem("token_Value");

  useEffect(() => {
    const fetchQuizQuestions = async () => {
      try {
        const response = await axios.get(`${BaseURL}courses/${courseId}/quiz/${quizId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        setQuestions(response.data.quiz.questions);
      } catch (error) {
        console.error("Error fetching quiz questions:", error);
        message.error("Failed to load quiz questions. Please try again later.");
      } finally {
        setLoading(false);
      }
    };
    fetchQuizQuestions();
  }, [quizId, courseId, token]);

  const handleAnswerSelect = (questionId, answerId) => {
    setSelectedAnswers((prev) => ({
      ...prev,
      [questionId]: answerId,
    }));
  };

  const handleNext = () => {
    setCurrentQuestionIndex((prevIndex) => Math.min(prevIndex + 1, questions.length - 1));
  };

  const handleBack = () => {
    setCurrentQuestionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleSubmit = async () => {
    const answers = Object.keys(selectedAnswers).map((questionId) => ({
      questionId: parseInt(questionId),
      answerId: selectedAnswers[questionId],
    }));

    const body = { answers };

    setLoading(true); // Show loading spinner while submitting

    try {
      const response = await axios.post(`${BaseURL}courses/${courseId}/quiz/${quizId}`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setResult(response.data);
      setShowResult(true);
    } catch (error) {
      console.error("Error submitting quiz:", error);
      setShowError(true);
      message.error("Error submitting quiz. Please try again.");
    } finally {
      setLoading(false); // Hide loading spinner after submission
    }
  };

  if (loading) return <Spin size="large" />; // Show loading spinner

  if (showResult && result.score==questions.length) return (
    <div className="result-container">
      <Result
        status="success"
        title={`Your Score: ${result.score}/${questions.length}`}
        subTitle="Thank you for participating!"
        extra={[
          <Button type="primary" key="back" onClick={() => window.location.reload()}>
            Try it again
          </Button>,
        ]}
      />
    </div>
  );else if(showResult && result.score!==questions.length) return(
    <div className="result-container">
      <Result
        status="warning"
        title={`Your Score: ${result.score}/${questions.length}`}
        subTitle="Thank you for participating!"
        extra={[
          <Button type="primary" key="back" onClick={() => window.location.reload()}>
            Try it again
          </Button>,
        ]}
      />
    </div>
  );
  if (showError) return (
    <div className="result-container">
      <Result
        status="error"
        title={`Your Score: 0/${questions.length}`}
        subTitle="Thank you for participating!"
        extra={[
          <Button type="primary" key="back" onClick={() => window.location.reload()}>
            Try it again
          </Button>,
        ]}
      />
    </div>
  );

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <div className="quiz-page">
      <Card title={`Question ${currentQuestionIndex + 1}`} style={{ width: '100%', maxWidth: 600, margin: 'auto' }}>
        <Title level={4}>{currentQuestion.text}</Title>
        <div className="answer-options">
          {currentQuestion.answers.map((answer) => (
            <Button
              key={answer.id}
              type={selectedAnswers[currentQuestion.id] === answer.id ? 'primary' : 'default'}
              onClick={() => handleAnswerSelect(currentQuestion.id, answer.id)}
              style={{ width: '100%', margin: '5px 0' }}
            >
              {answer.text}
            </Button>
          ))}
        </div>
        <div className="navigation-buttons" style={{ display: 'flex', justifyContent: 'space-between' }}>
          {currentQuestionIndex > 0 && (
            <Button className='bg-color' onClick={handleBack}>Back</Button>
          )}
          {currentQuestionIndex < questions.length - 1 ? (
            <Button type="primary" className='bg-color' onClick={handleNext}>Next</Button>
          ) : (
            <Button type="primary" className='bg-color' onClick={handleSubmit}>Submit</Button>
          )}
        </div>
      </Card>
    </div>
  );
}

export default QuizPage;
