import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { BaseURL } from "../../../api/BaseURL";
import play from "../../../Assets/play.svg";
import "./LessonAssign.css";

const LessonAssign = () => {
  const { courseId, quizId } = useParams();
  const navigate = useNavigate();
  const [courseDetails, setCourseDetails] = useState(null);
  const [quizScore, setQuizScore] = useState(null);
  const [quizError, setQuizError] = useState(false);
  const token = localStorage.getItem("token_Value");

  useEffect(() => {
    const fetchCourseDetails = async () => {
      try {
        const response = await fetch(`${BaseURL}courses/${courseId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (!response.ok) throw new Error("Failed to fetch course details");
        const data = await response.json();
        setCourseDetails(data.course);
      } catch (error) {
        console.error("Error fetching course details:", error);
      }
    };

    const fetchQuizScore = async () => {
      try {
        const response = await fetch(
          `${BaseURL}courses/${courseId}/quiz/${quizId}/score`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        if (!response.ok) throw new Error("Failed to fetch quiz score");
        const data = await response.json();

        if (data && typeof data.score === "number") {
          setQuizScore(data.score);
        } else {
          setQuizError(true);
        }
      } catch (error) {
        setQuizError(true);
        console.error("Error fetching quiz score:", error);
      }
    };

    fetchCourseDetails();
    fetchQuizScore();
  }, [courseId, quizId, token]);

  const handleStartQuiz = () => {
    if (quizScore === null && quizError) {
      navigate(`/course/${courseId}/quiz/${quizId}`);
    }
  };

  const handleRecapClick = () => {
    navigate(`/courses/${courseId}/lessons`);
  };

  return (
    <div className="lesson-assign-container">
      <h2 className="lesson-title">{courseDetails?.name || "Course Name"}</h2>

      <div className="lesson-row">
        <div className="lesson-description">
          <p>
            {courseDetails?.description || "Course description will be displayed here."}
          </p>
        </div>
        <div className="lesson-quiz">
          <h3>Quiz</h3>
          <div
            onClick={quizError ? handleStartQuiz : undefined}
            className={`quiz-circle ${quizError ? "clickable" : ""}`}
          >
            {!quizError ? quizScore : "Start Now"}
          </div>
        </div>
      </div>

      <div className="lesson-row">
        <div className="lesson-recap">
          <h3>Recap</h3>
          <button onClick={handleRecapClick} className="recap-button">
            <img src={play} alt="Play" className="play-icon" />
          </button>
        </div>
        <div className="lesson-resources">
          <h3>Resources</h3>
          <p>Book</p>
          <p>Slide</p>
          <p>PDF</p>
        </div>
        <div className="lesson-feedback">
          <h3>Feedback</h3>
          <p>Lesson Feedback</p>
        </div>
      </div>
    </div>
  );
};

export default LessonAssign;
