import React, { useState } from "react";
import { Button, message, Typography } from "antd";
import Navbar from "../DashboardNavbar/DashboardNavbar";
import { useNavigate, useParams } from "react-router-dom";
import { BaseURL } from "../../api/BaseURL";
import axios from "axios";
import { useMediaQuery } from "react-responsive";

const { Title } = Typography;

const AddQuizLesson = () => {
    const { courseId } = useParams();
    const isSmallScreen = useMediaQuery({ maxWidth: 768 });
    const { lessonId } = useParams();
    const [quizTitle, setQuizTitle] = useState('');
    const navigate = useNavigate();
    const [dueDate, setDueDate] = useState();
    const [questions, setQuestions] = useState([{ text: '', answers: [{ text: '', isCorrect: false }] }]);

    const handleQuizTitleChange = (e) => {
        setQuizTitle(e.target.value);
    };

    const handleDueDateChange = (e) => {
        setDueDate(e.target.value);
    };

    const handleQuestionChange = (index, value) => {
        const updatedQuestions = [...questions];
        updatedQuestions[index].text = value;
        setQuestions(updatedQuestions);
    };

    const handleAnswerChange = (questionIndex, answerIndex, value) => {
        const updatedQuestions = [...questions];
        updatedQuestions[questionIndex].answers[answerIndex].text = value;
        setQuestions(updatedQuestions);
    };

    const toggleCorrectAnswer = (questionIndex, answerIndex) => {
        const updatedQuestions = [...questions];
        updatedQuestions[questionIndex].answers.forEach((answer, idx) => {
            answer.isCorrect = idx === answerIndex;
        });
        setQuestions(updatedQuestions);
    };

    const addQuestion = () => {
        setQuestions([...questions, { text: '', answers: [{ text: '', isCorrect: false }] }]);
    };

    const deleteQuestion = (index) => {
        setQuestions(questions.filter((_, i) => i !== index));
    };

    const addAnswer = (questionIndex) => {
        const updatedQuestions = [...questions];
        updatedQuestions[questionIndex].answers.push({ text: '', isCorrect: false });
        setQuestions(updatedQuestions);
    };

    const deleteAnswer = (questionIndex, answerIndex) => {
        const updatedQuestions = [...questions];
        updatedQuestions[questionIndex].answers = updatedQuestions[questionIndex].answers.filter((_, i) => i !== answerIndex);
        setQuestions(updatedQuestions);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem("token_Value");

        const payload = {
            title: quizTitle,
            dueDate: dueDate,
            lessonId,
            questions: questions.map((q) => ({
                text: q.text,
                answers: q.answers.map((a) => ({
                    text: a.text,
                    isCorrect: a.isCorrect || false,
                })),
            })),
        };

        try {
            const response = await axios.post(`${BaseURL}courses/${courseId}/quiz`, payload, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            console.log('Quiz added successfully', response);
            message.success('Quiz added successfully');
            navigate(`/courseDetails-dashboard/${courseId}`);
        } catch (error) {
            console.error('Error adding quiz:', error);
        }
    };

    return (
        <div className="row" style={{ backgroundColor: "#F6FAFB" }}>
            {/* Sidebar */}
            <div className="col-md-2" style={{ backgroundColor: "#F6FAFB", padding: "20px" }}>
                {/* <Navbar /> */}
            </div>

            {/* Main Content */}
            <div className="col-md-10 px-5" style={{ padding: "20px" }}>
                <div className="d-flex align-items-center" style={{ paddingBottom: "10px" }}>
                    <Button
                    type="text"
                    onClick={() => navigate(-1)} // Or use navigate(-1) for going back to previous page
                    style={{ marginRight: "10px" }}
                    >
                    {"< Back"}
                    </Button>
                    <div className="w-100 d-flex justify-content-center align-items-end">
                        <h1
                            className="m-0"
                            style={{
                                fontSize: isSmallScreen ? "16px" : "28px",
                                fontWeight: "600",
                            }}
                        >
                            Dashboard
                        </h1>
                        <p
                            className="ml-3 text-muted"
                            style={{
                                marginLeft: "10px",
                                fontSize: isSmallScreen ? "10px" : "16px",
                            }}
                        >
                            / Create New Content / Add Quiz Lesson
                        </p>
                    </div>
                </div>

                <div className="create-content-page mt-4" style={{ backgroundColor: "#fff", padding: "40px", borderRadius: "8px", boxShadow: "0 1px 4px rgba(0,0,0,0.1)", maxWidth: "800px", margin: "0 auto" }}>
                    <Title level={3} style={{ marginBottom: "30px", textAlign: "center" }}>Add Quiz Lesson</Title>

                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label htmlFor="title" className="form-label">Quiz Title</label>
                            <input type="text" className="form-control" id="title" value={quizTitle} onChange={handleQuizTitleChange} required />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="title" className="form-label">Quiz Due Date</label>
                            <input type="number" className="form-control" id="title" value={dueDate} onChange={handleDueDateChange} required />
                        </div>

                        {questions.map((question, questionIndex) => (
                            <div key={questionIndex} className="mb-4">
                                <div className="d-flex justify-content-between">
                                    <h3>Question {questionIndex + 1}</h3>
                                    <Button type="danger" onClick={() => deleteQuestion(questionIndex)}>Delete Question</Button>
                                </div>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    placeholder="Enter question"
                                    value={question.text}
                                    onChange={(e) => handleQuestionChange(questionIndex, e.target.value)}
                                    required
                                />

                                {question.answers.map((answer, answerIndex) => (
                                    <div key={answerIndex} className="d-flex align-items-center mb-2">
                                        <input
                                            type="text"
                                            className="form-control me-2"
                                            placeholder="Enter answer"
                                            value={answer.text}
                                            onChange={(e) => handleAnswerChange(questionIndex, answerIndex, e.target.value)}
                                            required
                                        />
                                        <input
                                            type="radio"
                                            name={`correctAnswer-${questionIndex}`}
                                            checked={answer.isCorrect}
                                            onChange={() => toggleCorrectAnswer(questionIndex, answerIndex)}
                                        />
                                        <Button type="danger" className="ms-2" onClick={() => deleteAnswer(questionIndex, answerIndex)}>Delete Answer</Button>
                                    </div>
                                ))}

                                <Button type="dashed" onClick={() => addAnswer(questionIndex)}>+ Add Answer</Button>
                            </div>
                        ))}

                        <Button type="dashed" onClick={addQuestion}>+ Add Question</Button>
                        <div className="mt-4">
                            <Button type="primary" style={{background: '#008772'}} htmlType="submit">Save</Button>
                            <Button type="secondary" htmlType="reset" className="ms-2">Cancel</Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddQuizLesson;
