import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Overview from '../Overview/Overview';
import Assignment from '../Assignment/Assignment';
import Reports from '../Reports/Reports';
import Inbox from '../Inbox/Inbox';
import logo from '../../../Assets/logo.png';

const UserSideBar = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    // Helper function to get the base path (ignoring dynamic segments like `courseId` or `quizId`)
    const getBasePath = (path) => {
        const segments = path.split("/").slice(0, 4); // Take only the first 3 segments to ignore dynamic parts
        return segments.join("/");
    };

    // Sections to be displayed in the sidebar
    const sections = [
        {
            title: 'Overview',
            path: 'overview',
            fullPath: '/user/overview',
            icon: 'fa-solid fa-shapes',
            component: Overview
        },
        {
            title: 'Assignment',
            path: 'assignment',
            fullPath: '/user/assignment',
            icon: 'fa-solid fa-clipboard',
            component: Assignment
        },
        {
            title: 'Reports',
            path: 'reports',
            fullPath: '/user/reports',
            icon: 'fa-solid fa-chart-pie',
            component: Reports
        },
        {
            title: 'Inbox',
            path: 'inbox',
            fullPath: '/user/inbox',
            icon: 'fa-solid fa-inbox',
            component: Inbox
        },
    ];

    useEffect(() => {
        const currentPath = getBasePath(pathname); // Get the base path from the pathname
        console.log(currentPath);
        if(currentPath==='user/:courseId/:quizId'){
            navigate('/user/:courseId/:quizId');
            console.log(currentPath);
        }
        else if (!sections.some(section => getBasePath(section.fullPath) === currentPath)) {
            navigate('/user/overview');
        }
        }, []);

    return (
        <div className="d-flex flex-column flex-shrink-0 p-3 bg-light rounded-start">
            <div className='w-25'>
                <img src={logo} alt="Logo" />
            </div>

            <ul className="nav nav-pills flex-column mb-auto">
                {sections.map((section, index) => (
                    <li key={index} className={`my-1 border-bottom`}>
                        <Link
                            to={section.fullPath}
                            className={`nav-link ${pathname.startsWith(section.fullPath) ? 'link-light bg-color' : 'link-dark'}`}
                            aria-current={pathname.startsWith(section.fullPath) ? "page" : undefined}
                        >
                            <i className={section.icon}></i> {section.title}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default UserSideBar;
