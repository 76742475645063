import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Style from "./Pricing.module.css";
import { useDarkMode } from "../Contexts/DarkModeContext";
import { BaseURL } from "../../api/BaseURL";
import { message } from "antd";


export default function Pricing() {
  const { darkMode } = useDarkMode();
  const [paymentDetails, setPaymentDetails] = useState('')
  const navigate = useNavigate();
  const isScreenSmall = useMediaQuery({ maxWidth: 576 });
  const token = localStorage.getItem("token_Value");
  const pricingData = [
    {
      type: "Student",
      price: paymentDetails.yearly/100,
      period: "/YEAR",
      features: ["Components-driven system", "Sales-boosting landing pages", "Awesome Feather icon pack"],
      buttonLabel: "Subscribe Now",
      checkIconColor: "#000000",
      planType: "yearly"  // Added planType for identification
    },
    {
      type: "Student",
      price: paymentDetails.monthly/100,
      period: "/MONTH",
      features: ["Components-driven system", "Sales-boosting landing pages", "Awesome Feather icon pack"],
      buttonLabel: "Subscribe Now",
      checkIconColor: "#000000",
      planType: "monthly"  // Added planType for identification
    },
  ];
  const handleSubscriptionClick = async (planType) => {
    try {
      const response = await axios.get(
        planType === "yearly"
          ? `${BaseURL}payment/subscribeYearly` : `${BaseURL}payment/subscribeMonthly`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      const paymentLink = response.data.subscription;
  
      if (paymentLink) {
        window.location.href = paymentLink; // Redirect to Paymob payment page
      } else {
        console.error("Payment link not found in response.");
      }
    } catch (err) {
      console.error("Error fetching subscription data:", err);
  
      if (err.response) {
        console.error("API Error Response:", err.response.data);
  
        // Check for specific error message indicating an active subscription
        if (err.response.data.error?.includes("You already have an active subscription")) {
          message.error(
            `You already have an active subscription.`
          );
        } else if (err.response.data.error) {
          message.error(err.response.data.error); // Other API error messages
        } else {
          message.error("An unexpected error occurred. Please try again.");
        }
      } else {
        message.error(
          "Failed to communicate with the server. Check your connection."
        );
      }
    }
  };
  
  
  useEffect(()=>{
    const displayPaymentDetails = () => {
      axios.get(`${BaseURL}payment/subscriptionPricingDetails`,
        {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      ).then((res)=>{
        setPaymentDetails(res.data)
        console.log(res.data);
      })
    }
    displayPaymentDetails()
  },[])
  

  return (
    <>
      <Helmet>
        <title>Subscription</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <div className={`${darkMode ? "bg-dark" : "bg-white"}`}>
        <div className="container pt-5">
          <div className="pt-4 font-sm">
            <p className={`${darkMode ? "text-white" : "text-black"}`}>
              Enter Your Data{" "}
              <span className="text-color">
                <i className="fa-solid text-color fa-angles-right px-3"></i> Choose Your Subscription
              </span>
            </p>
          </div>
          <div className="row text-center py-4">
            <h3 className={`fw-bold ${darkMode ? "text-white" : "text-black"}`}>
              Choose your subscription with <span className="text-color">affordable price</span>
            </h3>
            <h4 className={`${darkMode ? "text-white" : "text-black"}`}>And enjoy all courses</h4>
          </div>

          <div className={`${isScreenSmall ? "container" : ""}`}>
            <div className="row justify-content-evenly py-5">
              {paymentDetails && pricingData?.map((item, index) => (
                <div
                key={index}
                className={`col-md-3 py-3 position-relative border p-4 ${item.shadow} rounded-4 ${isScreenSmall ? "mb-4" : ""
                  }`}
              >
                <div className="d-flex align-items-center">
                  <i className={`${Style.user} mx-2 fa-solid fa-${item.type === "Corporate" ? "users" : "user"}`}></i>
                  <p className="fw-bold text-color">{item.type}</p>
                </div>
                <h1 className={`fw-bold ${darkMode ? "text-white" : "text-black"}`}>
                  ${item.price} <span className="font-sm">{item.period}</span>
                </h1>
                {item.features.map((feature, fIndex) => (
                  <div key={fIndex} className="d-flex align-items-center my-3">
                    <div
                      className={`${item.type === "Corporate" ? "bg-success-subtle" : "bg-gold"
                        } rounded-pill d-flex align-items-center justify-content-center`}
                      style={{ width: "30px", height: "30px" }}
                    >
                      <i
                        className={`font-sm fa-solid fa-check ${item.type === "Corporate" ? "" : "text-white"}`}
                        style={{ color: item.checkIconColor }}
                      ></i>
                    </div>
                    <p className={`ms-2 font-sm ${darkMode ? "text-white" : "text-black"}`}>{feature}</p>
                  </div>
                ))}
                <div className={`w-100 my-3 ${isScreenSmall ? "mt-3" : ""} d-flex justify-content-start`}>
                  <button
                    onClick={() => handleSubscriptionClick(item.planType)}  // Call handler with plan type
                    className={`px-5 btn border border-1  ${darkMode ? "border-white" : "border-black"} rounded-4 text-color fw-bold font-sm`}
                  >
                    {item.buttonLabel}
                  </button>
                </div>
              </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
