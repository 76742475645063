import React, { useState } from "react";
import Style from "./Signup.module.css";
import signupImg from "../../Assets/img6.jpg";
import ggl from "../../Assets/gglicn.png";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import { useRegisterUserMutation } from "../../api/apiSlice";
import { useForm } from "react-hook-form";
import { message } from "antd";
import { useDarkMode } from "../Contexts/DarkModeContext";
import { useTranslation } from "react-i18next";

export default function Signup() {
  const { t } = useTranslation();
  const [phone, setPhone] = useState('');
  const { darkMode } = useDarkMode();
  const isScreenSmall = useMediaQuery({ maxWidth: 576 });
  let navigate = useNavigate();
  const [error, setError] = useState("");

  const [registerUser] = useRegisterUserMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      phone: "",
      role: "student",
    },
  });

  const onSubmit = async (data) => {
    try {
      console.log("Payload:", data); // Log payload
      const userData = await registerUser(data).unwrap();
      console.log("User Data after registration:", userData); // Log user data after registration succeeds
      message.success(t("Registration Success"));
      // navigate("/login");
      navigate("/verify", { state: { email: data.email } });
    } catch (error) {
      if (error.status === 400) {
        setError(t("signup.invalidInput"));
      } else if (error.status === 401) {
        setError(t("signup.unauthorized"));
      } else {
        setError(t("signup.networkError"));
      }
      console.error("Error during registration:", error); // Log the error for debugging
      setTimeout(() => setError(""), 3000);
    }
  };

  return (
    <>
      <Helmet>
        <title>{t("signup.title")}</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <div className={`${darkMode ? 'bg-dark' : 'bg-white'}`}>
        <div className="container pt-5">
          <div className="row py-5">
            <div className="col-md-6">
              <img src={signupImg} className="w-100 h-100 rounded-2" alt="" />
            </div>
            <div className="col-md-6">
              <div>
                <h5 className={`fw-bold text-center ${darkMode ? 'text-white' : 'text-black'}`}>
                  {t("signup.title")}
                </h5>
                <div className="d-flex justify-content-center align-items-center">
                  <h6 className={"text-color m-0"}>{t("signup.connectWith")}</h6>
                  <div className="d-flex align-items-center justify-content-center">
                    <div>
                      <i className={`${Style.blue} fa-brands fa-facebook mx-3`}></i>
                    </div>
                    <div style={{ width: "15px", height: "15px" }}>
                      <img src={ggl} className="w-100 mb-1 " alt="google logo" />
                    </div>
                  </div>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className={`col-md-6 ${isScreenSmall ? "px-2" : "mt-3 px-5"}`}>
                      <label
                        htmlFor="firstName"
                        className={Style.label + ` form-label fs-5 fw-bold ${darkMode ? 'text-white' : 'text-black'}`}
                      >
                        {t("signup.firstName")}
                      </label>
                      <input
                        type="text"
                        className={Style.inputField + " w-100 py-2 gray-border rounded-5 px-3"}
                        id="firstName"
                        name="firstName"
                        placeholder={t("signup.firstNamePlaceholder")}
                        {...register("firstName", {
                          required: t("signup.firstNameRequired"),
                        })}
                      />
                      {errors.firstName && (
                        <div className="text-danger mt-1">
                          {errors.firstName.message}
                        </div>
                      )}
                    </div>

                    <div className={`col-md-6 ${isScreenSmall ? "px-2" : "mt-3 px-5"}`}>
                      <label
                        htmlFor="lastName"
                        className={Style.label + ` form-label fs-5 fw-bold ${darkMode ? 'text-white' : 'text-black'}`}
                      >
                        {t("signup.lastName")}
                      </label>
                      <input
                        type="text"
                        className={Style.inputField + " w-100 py-2 gray-border rounded-5 px-3"}
                        id="lastName"
                        name="lastName"
                        placeholder={t("signup.lastNamePlaceholder")}
                        {...register("lastName", {
                          required: t("signup.lastNameRequired"),
                        })}
                      />
                      {errors.lastName && (
                        <div className="text-danger mt-1">
                          {errors.lastName.message}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className={`${isScreenSmall ? "px-2 mb-2" : "mt-3 px-5"}`}>
                    <label
                      htmlFor="email"
                      className={Style.label + ` form-label fs-5 fw-bold ${darkMode ? 'text-white' : 'text-black'}`}
                    >
                      {t("signup.email")}
                    </label>
                    <input
                      type="email"
                      className={Style.inputField + " w-100 py-2 gray-border rounded-5 px-3"}
                      id="email"
                      name="email"
                      placeholder={t("signup.emailPlaceholder")}
                      {...register("email", {
                        required: t("signup.emailRequired"),
                        pattern: {
                          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                          message: t("signup.invalidEmail"),
                        },
                      })}
                    />
                    {errors.email && (
                      <div className="text-danger mt-1">
                        {errors.email.message}
                      </div>
                    )}
                  </div>

                  <div className={`${isScreenSmall ? "px-2 mb-2" : "mt-3 px-5"}`}>
                    <label
                      htmlFor="password"
                      className={Style.label + ` form-label fs-5 fw-bold ${darkMode ? 'text-white' : 'text-black'}`}
                    >
                      {t("signup.password")}
                    </label>
                    <input
                      type="password"
                      className={Style.inputField + " w-100 py-2 gray-border rounded-5 px-3"}
                      id="password"
                      name="password"
                      placeholder={t("signup.passwordPlaceholder")}
                      {...register("password", {
                        required: t("signup.passwordRequired"),
                        pattern: {
                          value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                          message: t("signup.invalidPassword"),
                        },
                      })}
                    />
                    {errors.password && (
                      <div className="text-danger mt-1">
                        {errors.password.message}
                      </div>
                    )}
                  </div>
                  <div className={`${isScreenSmall ? "px-2 mb-2" : "mt-3 px-5"}`}>
                    <label
                      htmlFor="phone"
                      className={Style.label + ` form-label fs-5 fw-bold ${darkMode ? 'text-white' : 'text-black'}`}
                    >
                      {t("signup.phone")}
                    </label>
                    <PhoneInput
                      country={'eg'}
                      inputProps={{
                        name: 'phone',
                        id: 'phone',
                        required: true,
                        autoFocus: false,
                      }}
                      inputStyle={{
                        width: '100%',
                        height: '45px',
                        borderTopRightRadius: '50px',
                        borderBottomRightRadius: '50px',
                      }}
                      onChange={(phone) => {
                        setPhone(phone);
                        setValue("phone", phone);
                      }}
                      className={"w-100 rounded-5 mb-5"}
                      id="phone"
                      name="phone"
                      placeholder={t("signup.phonePlaceholder")}
                      
                    />
                    {errors.phone && (
                      <div className="text-danger mt-1">
                        {errors.phone.message}
                      </div>
                    )}
                  </div>

                  <div className="d-flex justify-content-end pe-5">
                    <button
                      type="submit"
                      className="btn bg-color rounded-5 text-white px-4 py-1"
                    >
                      {t("signup.continue")}
                    </button>
                  </div>

                  {error && (
                    <div className="alert alert-danger mt-3" role="alert">
                      {error}
                    </div>
                  )}
                </form>

                <div className={`text-center my-3 ${isScreenSmall ? "px-3" : "px-5"}`}>
                  <span className={` ${darkMode ? 'text-white' : 'text-black'}`}>
                    {t("signup.alreadyHaveAccount")}{" "}
                    <Link className="text-color fw-bold" to="/login">
                      {t("signup.loginNow")}
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
