import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Typography,
  Col,
  InputNumber,
  Input,
  Select,
  Row,
  message,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import Navbar from "../DashboardNavbar/DashboardNavbar";
import { Controller, useForm } from "react-hook-form";
import { useAddCourseMutation } from "../../api/apiSlice";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BaseURL } from "../../api/BaseURL";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

const { Option } = Select;
const { TextArea } = Input;
const { Title } = Typography;

const AddEvent = () => {
    const { user } = useSelector((state) => state.auth);
  const isSmallScreen = useMediaQuery({ maxWidth: 768 });
  const [addCourse] = useAddCourseMutation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    code: "",
    description: "",
    category: "",
    instructor: user.id,
    level: "",
    sections: "",
    duration: "",
    image: "",
    price: "",
  });
  // const {
  //   handleSubmit,
  //   control,
  //   formState: { errors },
  // } = useForm();

  function getData(e) {
    let data = { ...formData };
    if([e.target.name]=='image'){
      data[e.target.name] = e.target.files[0];
    }else{
      data[e.target.name] = e.target.value;
    }
    setFormData(data);
    console.log(data);
  }

  function handleSubmit(e){
    e.preventDefault();
    const token = localStorage.getItem("token_Value")
    axios.post(BaseURL + `courses/`,formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    }).then((response)=>{
      console.log('data sent',response)
      message.success('Couses was added successfully');
      navigate('/courses-dashboard')
    }).catch((err)=>{
      console.log(err)
    })
  }
    return (
        <div className="row" style={{ backgroundColor: "#F6FAFB" }}>
            {/* Sidebar */}
            <div
                className="col-md-2"
                style={{
                    backgroundColor: "#F6FAFB",
                    padding: "20px",
                }}
            >
                {/* <Navbar /> */}
            </div>

            {/* Main Content */}
            <div className="col-md-10 px-5" style={{ padding: "20px" }}>
                {/* Dashboard Title */}
                <div
                    className="d-flex justify-content-between align-items-center"
                    style={{ paddingBottom: "10px" }}
                >
                    <Button
                        type="text"
                        onClick={() => navigate(-1)} // Or use navigate(-1) for going back to previous page
                        style={{ marginRight: "10px" }}
                    >
                        {"< Back"}
                    </Button>
                    <div className="w-100 d-flex justify-content-center align-items-end">
                        <h1
                            className="m-0"
                            style={{
                                fontSize: isSmallScreen ? "18px" : "28px",
                                fontWeight: "600",
                            }}
                        >
                            Dashboard
                        </h1>
                        <p
                            className="ml-3 text-muted"
                            style={{
                                marginLeft: "10px",
                                fontSize: isSmallScreen ? "12px" : "16px",
                            }}
                        >
                            / Add Event
                        </p>
                    </div>
                </div>

                {/* Content Section */}
                <div
                    className="create-content-page mt-4"
                    style={{
                        backgroundColor: "#fff",
                        padding: "40px",
                        borderRadius: "8px",
                        boxShadow: "0 1px 4px rgba(0,0,0,0.1)",
                        width: "100%",
                        maxWidth: "800px",
                        margin: "0 auto",
                    }}
                >
                    <Title
                        level={3}
                        style={{ marginBottom: "30px", textAlign: "center" }}
                    >
                        Add Event
                    </Title>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-3">
                            <div className="col-md-6">
                                <div className="w-100">
                                    <label htmlFor="name" className="form-label">Name</label>
                                    <input
                                        type="text"
                                        className="form-control text-gray-700 appearance-none border  rounded-md"
                                        id="name"
                                        name="name"
                                        placeholder="Enter course name"
                                        onChange={getData}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="w-100">
                                    <label htmlFor="code" className="form-label">Date</label>
                                    <input
                                        type="text"
                                        className="form-control text-gray-700 appearance-none border  rounded-md"
                                        id="code"
                                        name="code"
                                        placeholder="Enter course code"
                                        onChange={getData}
                                        required
                                    />
                                </div>
                            </div>
                            
                            <div className="col-md-12">
                                <div className="w-100">
                                    <label htmlFor="image" className="form-label">Image</label>
                                    <input
                                        type="file"
                                        className="form-control text-gray-700 appearance-none border rounded-md"
                                        id="image"
                                        name="image"
                                        accept="image/*"
                                        placeholder="Enter course image"
                                        onChange={getData}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="w-100">
                                    <label htmlFor="description" className="form-label">Description</label>
                                    <textarea
                                        className="form-control text-gray-700 appearance-none border rounded-md"
                                        id="description"
                                        name="description"
                                        placeholder="Enter course description"
                                        onChange={getData}
                                        required
                                    ></textarea>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="w-100">
                                    <button type="submit" className="btn bg-color me-2">Save</button>
                                    <button type="reset" className="btn btn-secondary ms-2">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddEvent