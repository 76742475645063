import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import io from 'socket.io-client';
import EmojiPicker from 'emoji-picker-react';
import { BaseURL } from '../../../api/BaseURL';
import { jwtDecode } from "jwt-decode";
import axios from 'axios';

const socket = io('https://api.usamif.com/');

export default function Inbox() {
    // Search by email
    const [searchResult, setSearchResult] = useState(null);
    const [searchEmail, setSearchEmail] = useState('');
    const token = localStorage.getItem('token_Value');

    const handleSearch = async () => {
      if (searchEmail.trim()) {
        console.log(searchEmail);
        setSearchResult(null);
        try {
          console.log(`${BaseURL}users/search?email=${searchEmail}`);
          const response = await axios.get(
            `${BaseURL}users/search?email=${searchEmail}`,
            {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            }
          );
          console.log(response);
          if (response.data) {
            setSearchResult(response.data.user);
            console.log(searchResult);
          }
        } catch (error) {
          console.error(error.response.data.message);
          alert(error.response.data.message);
        }
      }
    };

  const encodedToken = localStorage.getItem('token_Value');
  let userData = jwtDecode(encodedToken);
  console.log(userData);
  const senderId = userData?.id; 
  console.log("Sender ID:", senderId);

  const location = useLocation();
  const initialReceiver = location.state?.freelancer;
  const [receiverId, setReceiverId] = useState(initialReceiver?.id || null);
  console.log("Receiver ID:", receiverId);

  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [users, setUsers] = useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const messagesRef = useRef(null);
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState(null);
  const [highLight, setHighLight] = useState(null);

  // Join the socket room after connecting
  useEffect(() => {
    if (senderId) {
      socket.emit('join', senderId); // Join user's own room for message receiving
      console.log(`User ${senderId} joined room`);
    }
  }, [senderId]);

  // Load previous messages when receiverId changes
  useEffect(() => {
    if (receiverId) {
      loadPreviousMessages();
    }
  }, [receiverId]);

  const loadPreviousMessages = () => {
    socket.emit('loadMessages', { senderId, receiverId });
    socket.on('previousMessages', (loadedMessages) => {
      setMessages(loadedMessages);
      console.log("Loaded previous messages:", loadedMessages);
    });
  };

  // Real-time message reception
  useEffect(() => {
    socket.on('receiveMessage', (newMessage) => {
      if (newMessage) {
        console.log("Received message:", newMessage);
        setMessages((prevMessages) => [...prevMessages, newMessage]);

        // If the new message is from a different user, update the receiverId to allow response
        if (newMessage.senderId !== senderId) {
          setHighLight(newMessage.senderId);
          setReceiverId(newMessage.senderId);
        }
      }
    });

    return () => {
      socket.off('receiveMessage');
    };
  }, [senderId]);

    // scrole effect 
    //  useEffect(()=>{
    //   if (messagesRef.current) {
    //     messagesRef.current.scrollIntoView({behavior: 'smooth'});
    //   }
    // }, [messages]);
    
  const handleMessageSubmit = (e) => {
    e.preventDefault();
    if (message.trim() && receiverId) {
      const messageData = {
        senderId,
        receiverId,
        message,
        username: userData.username,
      };
      console.log("Sending message:", messageData);
      socket.emit('sendMessage', messageData);
      
      // Update the messages array with the sender's own message
      setMessages((prevMessages) => [...prevMessages, messageData]);
      setMessage('');
    }
  };

  // Load freelancers the client has chatted with
  useEffect(() => {
    if (senderId) {
      socket.emit('freelancersList', senderId);
      socket.on('freelancersData', (data) => {
        setUsers(data.users);
        console.log("Fetched freelancers:", data.users);
      });

      return () => {
        socket.off('freelancersData');
      };
    }
  }, [senderId]);

  return (
    <div className="container mt-4 mx-auto">
    <div className="card shadow border">
      <div className="card-header bg-success text-white d-flex justify-content-between align-items-center">
        <h5 className="mb-0">USAM Chat</h5>
        {/* <button className="btn btn-danger" onClick={leaveChat}>Leave Chat</button> */}
      </div>
  
      <div className="row no-gutters">
        {/* Sidebar for freelancers list */}
        <div className="col-md-4 bg-light p-3 overflow-auto" style={{ maxHeight: '350px' }}>
          {/* Search Bar */}
          <div className="d-flex mb-3 align-items-center">
            <input
              type="email"
              className="form-control me-2"
              placeholder="Search by email"
              value={searchEmail}
              onChange={(e) => setSearchEmail(e.target.value)}
            />
            <i
              onClick={handleSearch}
              className="fa fa-search text-success cursor-pointer"
              style={{ cursor: 'pointer' }}
            />
          </div>
  
          {/* Search Results */}
          <ul className="list-unstyled">
            {searchResult && (
              <li
                key={searchResult.id}
                className={`list-group-item d-flex align-items-center p-2 ${
                  highLight === searchResult.id || receiverId === searchResult.receiverId ? 'bg-success text-white' : ''
                }`}
                onClick={() => {
                  setReceiverId(searchResult.id);
                  setSelectedUser(searchResult.id);
                  setHighLight(searchResult.id);
                }}
              >
                <img
                  src={`https://ui-avatars.com/api/?name=${searchResult.firstName + ' ' + searchResult.lastName}`}
                  alt={searchResult.firstName}
                  className="rounded-circle me-3"
                  style={{ width: '40px', height: '40px' }}
                />
                <span className="fw-bold">{searchResult.firstName + ' ' + searchResult.lastName}</span>
              </li>
            )}
          </ul>
  
          <h6 className="text-success mt-3">Previous Chats</h6>
          <hr />
          <ul className="list-unstyled">
            {users.map((user) => (
              <li
                key={user.receiverId}
                className={`list-group-item d-flex align-items-center p-2 ${
                  highLight === user.receiverId || receiverId === user.receiverId ? 'bg-success text-white' : ''
                }`}
                onClick={() => {
                  setReceiverId(user.receiverId);
                  setSelectedUser(user.receiverId);
                  setHighLight(user.receiverId);
                  console.log(`Selected receiverId: ${user.receiverId}`);
                }}
              >
                <img
                  src={`https://ui-avatars.com/api/?name=${user.name}`}
                  alt={user.name}
                  className="rounded-circle me-3"
                  style={{ width: '40px', height: '40px' }}
                />
                <span className="fw-bold">{user.name}</span>
              </li>
            ))}
          </ul>
        </div>
  
        {/* Messages area */}
        <div className="col-md-8 bg-white p-3 overflow-auto" style={{ maxHeight: '400px' }}>
          {messages.map((msg, index) => (
            <div key={index} className="d-flex align-items-center mb-2">
              <img
                src={`https://ui-avatars.com/api/?name=${users.find((e) => e.receiverId === msg.senderId)?.name || 'US'}`}
                alt={msg.receiverName}
                className="rounded-circle me-2"
                style={{ width: '40px', height: '40px' }}
              />
              <strong className="me-2 text-success">
                {users.find((e) => e.receiverId === msg.senderId)?.name || 'US'}
              </strong>
              <span>{msg.message}</span>
            </div>
          ))}
          <div ref={messagesRef}></div>
        </div>
      </div>
  
      {/* Message input area */}
      <div className="card-footer bg-light position-relative">
        <form onSubmit={handleMessageSubmit} className="d-flex">
          <input
            type="text"
            className="form-control me-2"
            placeholder="Enter Message"
            value={message}
            required
            autoComplete="off"
            onChange={(e) => setMessage(e.target.value)}
          />
          <button className="btn btn-success me-2">
            <i className="fas fa-paper-plane"></i>
          </button>
          <button
            className="btn btn-light border text-success"
            onClick={(e) => {
              e.preventDefault();
              setShowEmojiPicker(!showEmojiPicker);
            }}
          >
            <i className="far fa-laugh"></i>
          </button>
        </form>
  
        {showEmojiPicker && (
          <div className="position-absolute bottom-100 end-0 shadow bg-white rounded border p-2">
            <EmojiPicker onEmojiClick={(emojiObject) => setMessage((prev) => prev + emojiObject.emoji)} />
          </div>
        )}
      </div>
    </div>
  </div>
  );
}
